/**
 * Describes the SearchFilter
 * for options @see SearchFilterOptions
 *
 * @class      SearchFilter (name)
 */
export default class SearchFilter{

	constructor(ID, slug, options) {
		this.ID = ID;
		this.slug = slug;
		this.options = options;
	}
}