import React, { Component } from 'react';
import Merkmal from './Merkmal';
import Helper from '../tools/Helper';

/**
 * renders a Group from API results
 * by looping through all "merkmale"
 *
 * @class      Group (name)
 * @prop 		{json} - API result data for one group
 */
class Group extends Component {

	render() {

		let group_data = this.props.group_data;
    let ausnahmeMerkmalTypeIds = this.props.p_merkmaltypeid_off;
    if(ausnahmeMerkmalTypeIds){
      ausnahmeMerkmalTypeIds = ausnahmeMerkmalTypeIds.toUpperCase();
    }else{
      ausnahmeMerkmalTypeIds = '';
    }

		let merkmale = [];
		let counter = 0;

    // ToDo delete: brauchen wir nicht mehr?
		let title = Helper.cutStr(group_data.GroupDesignation, '(FP) ');

		for(let merkmal_data of group_data.Merkmale)
		{
      if(ausnahmeMerkmalTypeIds.indexOf(';'+merkmal_data.MerkmalTypeId.toUpperCase()+';') === -1){
  			merkmale.push(
  					<Merkmal merkmal_data={merkmal_data} key={'merkmal-' + ++counter}></Merkmal>
  				);
      }
		}

		let classes = ['group', 'group-' + Helper.makeSafeForCSS(title)];
		let classname = classes.join(" ");

		if(merkmale.length){
			return(
				<div className={classname}>
					<h3 className="title">{title}</h3>
					{merkmale}
				</div>
			);
		}

		else return true;
	}
}

export default Group;
