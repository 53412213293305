
import React from 'react';

import IImgHeaderLogoMandant from './sites/stuttgart/media/logo.svg';
import IImgFooterGebit from './sites/thueringen/media/logo_gebit.png';

import IImgHeaderHauptbild from './sites/stuttgart/media/header/header_slider_1.jpg';
import IImgUeberUns from './sites/stuttgart/media/header/header_home.jpg';

export default class Config{

	static isLocalhost = window.location.hostname.toLowerCase().indexOf("localhost")>-1 || window.location.hostname.toLowerCase().indexOf("127.0.0.1")>-1;
	static DEBUG = false || this.isLocalhost;
	//todo: change tracker url
	static doTRACKER = window.location.hostname.toLowerCase().indexOf("stuttgart.info")>-1;

	//*****************************************************
	//*		Stuttgart
	//*   1. update: Verlinkung aus der Detailsansicht öffnet die Seite in einem neuen Tab
	//*****************************************************
	static LASTUPDATE = '2021.07.12';
	static VERSION = '1.1.6.3';
	static TRACKER = {
		url: 'https://analytics.gebit-ms.de/piwik.php',
		id: 19,
		active: this.doTRACKER
	};

	static BEARER_TOKEN = '77a709937b2c40eab4a4287c054f4539';
	// static BEARER_TOKEN_QUICKSEARCH = 'e95d358ec6ff4c16bdc01660d24d7af4';

	//stuttgart dev
	static MAPS_API_KEY = "AIzaSyAGduDfYNwFPXz3Dks0pJKoHSsbt04nsDQ";
	//stuttgart old > wird getauscht für live
	// static MAPS_API_KEY = "AIzaSyA8GW14IvSOycPAqWpe-EE03mSc_MN65TI";
	// static MAPS_API_KEY = this.doTRACKER ? "AIzaSyA8GW14IvSOycPAqWpe-EE03mSc_MN65TI" : "AIzaSyAGduDfYNwFPXz3Dks0pJKoHSsbt04nsDQ";

	//LIVE
	// static API_URL = 'https://stuttgart.fabis.pro/api';
	//DEBUG THÜRINGEN
	static API_URL = 'https://familienkompass-thueringen.fabis.pro/api';

	// DEV
	//static API_URL = 'https://relaunch.fabis.pro/api';
	//static API_URL = 'http://localhost:58579/api';

	//MAIL
	static MAIL_URL = `${this.API_URL}/sendmail`;
	static CAPTCHA_KEY = "6LcKa8gaAAAAALjeFRYNgkBIbevzI15TXFuetUv2";


	// **************************************
	// Zentrale Konstanten [Start]
	// **************************************
	static PROJECT_FOLDER_NAME = "Stuttgart";

	// Aus dieser Tabelle werden Kommunen für das Kontaktformular geladen
	// Wenn da eine Kommune vorhanden ist, wird die intern für das sendmail() benutzt,
	// sonst muss der Nutzer aktiv eine Kommune auswähöen, an wen die Anfrage gerichtet ist
	static TABLE_KOMMUNEN = "coreKommunen";

	//https://www.npmjs.com/package/http-status-codes
	static REQUESTED_RANGE_NOT_SATISFIABLE = "416";
	static REQUESTED_ERROR_GEOGRAPHY_INVALID = {c_id:"0335",
		c_message:"Leider konnten keine Angebote mit der Umkreis-Suche gesucht werden. Bitte korrigieren oder ergänzen Sie Ihre Ort/PLZ-Eingabe!"};
	static IconResultSetNeu = 'KeyIconNeu';
	// **************************************
	// Zentrale Konstanten [ENDE]
	// **************************************


	static HERO_TITLE = "Frühe Hilfen in Stuttgart";
	static HERO_BODY = "Informationen für Familien in den ersten Lebensjahren";

	static FILTER_IDS = {
		district: "9d46e9f4-44e1-4e29-9b38-9079fde9da2f", //<=MerkmalId, Regionalsuche, Kommune
		audience: "9d29a84c-0ec9-4c9f-bd0c-2764837f8d84", //<=MerkmalId, Zielgruppe, ListenId => '29bf5d88-6bd0-4dee-83cd-8bef1f4873d2'
		topic: "03af4836-f78c-4f82-9b74-e2dcfdf6eda5", //Themen MerkmalTypeId, ListenId => '5450032c-3aa4-452e-836b-69d172c49bf2'
		kids_age: "18edf28d-2479-4702-8f7b-61c7cdad71a1"
	};

	// static FILTER_IDS = {
	// 	district: "32eebb0a-e888-4048-83b3-dff359b76877", //<=MerkmalId, Regionalsuche, Kommune
	// 	audience: "8836473e-fd31-447d-aea0-f79b31c3435d", //<=MerkmalId, Zielgruppe, ListenId => '29bf5d88-6bd0-4dee-83cd-8bef1f4873d2'
	// 	topic: "a1bd9d4b-a7d3-4205-a6d5-28aa94586df5" //Themen MerkmalTypeId, ListenId => '5450032c-3aa4-452e-836b-69d172c49bf2'
	// };

	//MerkmalTypeId's  for Result Content
	static RESULT_IDS = {
		short_description: '5E1D6598-4504-4E06-8384-82A5AA58F22E',//Kurzbeschreibung des Angebotes;
		topic: 'a1bd9d4b-a7d3-4205-a6d5-28aa94586df5',
		region: '32eebb0a-e888-4048-83b3-dff359b76877',
		address: 'a34b1312-590e-49db-932d-844fa3558ced',
		email: 'd8c5dd3f-d424-4fda-93fd-baae0d5505df',
		mobil: '4f786c2d-9e26-4ae3-94f8-b6a639952d6d',
		contact_name: '8ddf6677-e89b-4913-990d-f2b84ce9ba6f',
		accessibility_st: '3a1e5f8c-6f8f-4081-b6b2-35a84921be31',

		//bei allen Mandanten gleich
		event_start_date: '3e0fe833-bc7d-4f40-92ce-4fc558154b1c',
		event_start_time: '4dd8d128-f826-4ccb-b466-8493719d8552',
		event_end_date: '91f69948-50bc-4406-a996-1b9cc39ca1c5',
		event_end_time: 'b2e3a2b3-6e40-4561-a705-bf076d12032b',
		accessibility: '49e95931-0aaa-45c1-b478-febf204e4c00'
		//Kontakt-Gruppe von Organisationen um in der Detailansicht einer Organisation die Kontaktdaten im Header zu schreiben
		//,contact_group: '86a5bb9d-81cf-4fc8-a684-00317368a079'
		// //öffnungszeiten für FFO
		// ,offer_opening_hours: 'f64100b4-31ad-4614-b127-4d40917777ba'
		// ,offer_phone: '4f2fdc99-0f03-49a4-a5fc-c34e5026e6be'
	};
	static RESULT_IDS_PROVIDER = {
		short_description: 'B3EF6AB5-EE63-4E83-8DB1-C60D7EDC2DA0',
		contact_group: '6e82be26-df97-43b3-880b-c6e242b989f2'
	};

	//todo: wo wird das benutzt?
	//Diese Merkmaltypen sind Aussnahmen. Sie kommen vom Backend, werden aber nicht in der Detailansicht angezeigt.
	//Alle weiteren Merkmale werden aus den Gruppen in der Ansicht geladen
	static DETAILVIEW_MERKMALTYPE_OFF = ';'+this.RESULT_IDS.event_start_date+';'
		+this.RESULT_IDS.event_start_time+';'
		+this.RESULT_IDS.event_end_date+';'
		+this.RESULT_IDS.event_end_time+';'
		+this.RESULT_IDS.short_description+';'
		+this.RESULT_IDS_PROVIDER.short_description+';'
		+'';
	static DETAILVIEW_MERKMALTYPE_OFF_PROVIDER = ';'+this.RESULT_IDS_PROVIDER.short_description+';';


	//css-Klassennamen, z.B. SearchCard befindet sich gerade in CreateSearchCard.js
	static FONT_RESIZE_COMPONENTS = ['SearchCard', 'SearchTopic', 'SearchProvidersHierarchy', 'SearchAudience', 'ResultSet', 'SearchDetails', 'LayoutDefault'];

	//Filtertexte
	static FILTERTEXT_REGION_PLACEHOLDER_HEADER = "Kommune";

	// Medien
	static ImgHeaderLogoMandant = IImgHeaderLogoMandant;

	static ImgFooterGebit = IImgFooterGebit;
	static FooterTextLinks = "gefördert durch:";
	static FooterTextRechts = "gefördert von:";
	static FooterUmsetzungText = "Das Webportal XXX.XXX wurde von der GEBIT Münster GmbH & Co. KG in Kooperation mit der Stadt Stuttgart erstellt.";

	static ImgHeaderHauptbild = IImgHeaderHauptbild;
	static ImgUeberUns = IImgUeberUns;

	static IconResultSetSvgOrt ='KeyIconSvgOrt';
	static IconResultSetSvgOrt_Filled = 'KeyIconSvgOrt_filled';

	static IconSvgRollstuhl ='KeyIconSvgRollstuhl';
	static IconSvgParkplatz ='KeyIconSvgParkplatz';
	static IconSvgWc ='KeyIconSvgWc';
	static IconSvgTaub ='KeyIconSvgTaub';
	static IconSvgBlind ='KeyIconSvgBlind';
	static IconSvgAufzug ='KeyIconSvgAufzug';
	static IconSvgKinderwagen ='KeyIconSvgKinderwagen';
	static IconSvgs_Map = {
		'00070': this.IconSvgRollstuhl,
		'00020': this.IconSvgParkplatz,
		'00030': this.IconSvgWc,
		'00040': this.IconSvgTaub,
		'00050': this.IconSvgBlind,
		'00060': this.IconSvgAufzug,
		'81': this.IconSvgKinderwagen
	};

	static FILTER_OFFER_TYPES = "offer_types";

	static QUICKSEARCH_ITEMS = 10;
	static PAGINATION_ITEMS = 5;
	static DISABLE_DEFAULT_DATEFILTER = false;

	static DATASET_PUBLISHED_DAYS_AGO = -28;

	//API-Constants
	static ApiKey_DatasetSubTypeOffer = "Offer";
}
