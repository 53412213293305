import React from 'react';
import ResultSet from './ResultSet';
import Config from '../../Config';
import DateFilter from './DateFilter';
import Merkmal from '../results/Merkmal';
import ResultHelper from '../results/ResultHelper';

/**
 * Has only static classes, that allow the creation of ResultSet components.
 * Also filters the API resuls for the MapResults
 *
 * @class      ResultSetFactory (name)
 */
export default class ResultSetFactory{

	static extractOffers(json_data)
	{
		//extract all offers
		let offers = [];
		for(let offer of json_data)
		{
			if(offer.DatasetSubType === "Offer")
			{
				offers.push(offer);
			}
		}

		return offers;
	}

	static createDateTags(date){
		let dateArray=date.split('.');
		let classes=["tag","monat","jahr"]
		let dateItems=[];
		for (var i=0; i<3; i++)
		{
			//set month name
			if(i === 1)
			{
				let months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
				dateArray[i] = months[dateArray[i]-1];
			}

			dateItems.push(
				<span className={classes[i]} key={i}>{dateArray[i]}</span>
			);
		}
		return (<div>{dateItems}</div>);
	}

	static buildResultSets(offers, start, end, callBack, callBackMap, max_length = 0){

		let results = [];

		let topic, region, short_description, address;
		let merkmal;
		let data;

    let guidShortDescription = '';

		let providersForOffersSpezialGuids = null;
		let providersForOffersSpezialText = null;
		let providerOffersSpezialDatas = Config.SEARCHRESULTS_PROVIDERS_FOR_SPECIAL_OFFERS;
		if(providerOffersSpezialDatas){
			providersForOffersSpezialGuids = providerOffersSpezialDatas.provider_spezial_guids.toUpperCase();
		  providersForOffersSpezialText = <p className='cProviderOfferSpezial'>{providerOffersSpezialDatas.provider_spezial_text}</p>;
		}

		for(let index = start ; index < end ; index++)
		{
			data = offers[index];

      if(data.DatasetSubType === Config.ApiKey_DatasetSubTypeOffer){
        guidShortDescription = Config.RESULT_IDS.short_description;
      }else{
        guidShortDescription = Config.RESULT_IDS_PROVIDER.short_description;
      }
			if(data)
			{
				//get topic
				merkmal = ResultHelper.searchMerkmalId(data, Config.RESULT_IDS.topic);
				topic = ResultHelper.getFirstValue(merkmal);

				//get region
				merkmal = ResultHelper.searchMerkmalId(data, Config.RESULT_IDS.region);
				let num_regions = ResultHelper.lengthMerkmal(merkmal);
				if(num_regions == 1)
				{
					region = ResultHelper.getFirstValue(merkmal);
				}
				else if(num_regions > 1)
				{
					region = "mehrere Orte";
				}

				//get short description
				merkmal = ResultHelper.searchMerkmalId(data, guidShortDescription);
				short_description = ResultHelper.getFirstValue(merkmal);

				//get address
				merkmal = ResultHelper.searchMerkmalId(data, Config.RESULT_IDS.address);
				let address = ResultHelper.getAddress(merkmal);

				//get accessibility data
				merkmal = ResultHelper.searchMerkmalId(data, Config.RESULT_IDS.accessibility);
				let accessibility_data = [];
				if(merkmal)
				{
					for(const entry of merkmal.MultipleMerkmalId)
					{
						accessibility_data.push({
							id: entry.Values[0].FieldData,
							alt: entry.Values[0].Value
						});
					}
				}

				let elemOfferSpezialFlag = '';
        if(providersForOffersSpezialGuids !== null && providersForOffersSpezialGuids.indexOf(';'+ data.ParentId.toUpperCase() +';') > -1){
					elemOfferSpezialFlag = providersForOffersSpezialText;
        }

				results.push(
						<ResultSet
							key = {index}
							date = {ResultSetFactory.createDateTags(data.NextDate)}
							time = {data.NextTime}
							topic = {topic}
							description = {short_description}
							offer_name = {data.LongName}
							orga_name = {data.ParentLongName}
							region = {region}
							id = {data.DatasetId}
							address = {address}
							callBack = {callBack}
							callBackMap = {callBackMap}
							max_length = {max_length}
							accessibility_data = {accessibility_data}
							p_datePublished = {data.DatePublished}
							p_elemspezialprovideroffer = {elemOfferSpezialFlag}
							>
						</ResultSet>
				);
			}
		}

		return results;
	}

	static buildMapResults(offers, start, end, callBack){

		let results = [];
		let data;

		for(let index = start ; index < end ; index++)
		{
			data = offers[index];

			if(data)
			{
				results.push(data);
			}
		}

		return results;
	}
}
