import React, { Component } from 'react';
import Pagination from "react-js-pagination";
import { withRouter } from "react-router";
import Select from 'react-select';
import { scroller } from 'react-scroll';

import {AppContext} from '../app/AppContext';
import Config from '../../Config';

import ResultSetFactory from '../model/ResultSetFactory';
import SearchResultsWithMap from './SearchResultsWithMap';

import MessageSharePartial from './MessageSharePartial';


/**
 * This component renders the search results as ResultSets, that are created with the ResultSetFactory.
 * The ResultSets are childs of the SearchResultsWithMap Wrapper, which renders the Google Map view.
 *
 * @class      SearchResults (name)
 * @prop 		{json} results - json search results from API
 * @prop 		{function} filterCallBack -
 */

class SearchResults extends Component {

	constructor(props)
	{
		super(props);

		this.map_results = null;
		this.show_pagination = false;
		this.all_offers = null;

		this.state = {
			search_results: null,
			activePage: 1,
			totalItemsCount: 0,
			itemsCountPerPage: {value: Config.PAGINATION_ITEMS, label: Config.PAGINATION_ITEMS},
			zoomToID: null
		}

		this.handlePageChange = this.handlePageChange.bind(this);
		this.highlightDataSet = this.highlightDataSet.bind(this);
	}

	componentDidMount() {

        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });

		this.updatePager();

	}

	componentDidUpdate(prevProps, prevState) {

		//check for page_num in url
		let page_num = this.props.match.params.page_num;

		//when it is different, change activePage to page_num
		if(page_num && page_num != this.state.activePage)
		{
			this.setState({
				activePage: parseInt(page_num)
			});
		}
		else if(this.state.activePage !== 1 && !page_num)
		{
			this.setState({
				activePage: 1
			});
		}

		if(prevProps.results !== this.props.results)
		{
			this.updatePager();
		}
	}

	updatePager()
	{
		//extract offers
		if(Array.isArray(this.props.results))
		{
			this.all_offers = ResultSetFactory.extractOffers(this.props.results);
		}
		else
		{
			this.all_offers = [];
		}

		this.setState({
			totalItemsCount: this.all_offers.length,
			activePage: 1
		});
	}

	/**
	 * Pager change page handler
	 *
	 * @param      {int}  page_number  The page number
	 */
	handlePageChange(page_number) {
    scroller.scrollTo('scroll_start', {
      duration: 1000,
      smooth: true
    });
		this.setState({activePage: page_number}, () => {
			//set page number in url to access when moving through history with the browser
			this.props.history.push('/ergebnisse/' + page_number);
		});
	}

	handleItemsPerPage(options){
		this.setState({
			itemsCountPerPage: options,
			activePage: 1
		});
	}

	resetAllFilters()
	{
		//todo: reset filters & make new search
		this.context.callApiSearchResultsWithoutAllFilters();
	}

	/**
	 * Renders the json results through the ResultSetFactory into ResultSets.
	 * Filters the json data for the map_results.
	 *
	 * @param      {<type>}  json_data  The json data
	 * @return     {Array}   All ResultSets
	 */
	createSearchResults(json_data){

		let results = null;
		let ndate = null;
		this.map_results = [];

		//check for error
		if(json_data.ID)
		{
			this.show_pagination = false;
      switch (json_data.ID.toString()) {
        case Config.REQUESTED_ERROR_GEOGRAPHY_INVALID.c_id:
          //show API Error
          results = (
            <div className="alert alert-danger mt-5" role="alert">
                {Config.REQUESTED_ERROR_GEOGRAPHY_INVALID.c_message}
            </div>
            );
        break;
        default:
          //show API Error
          results = (
            <div className="alert alert-danger mt-5" role="alert">
                {json_data.ID}: {json_data.Description}
            </div>
            );
        break;
      }
		}
		else if(this.all_offers && this.all_offers.length)
		{
			this.show_pagination = true;
			results = [];

			let start_item = (this.state.activePage-1) * this.state.itemsCountPerPage.value;
			let end_item = this.state.activePage * this.state.itemsCountPerPage.value;

			//check max_length
			if(end_item > this.all_offers.length)
				end_item = this.all_offers.length;

			//create result sets
			results = ResultSetFactory.buildResultSets(this.all_offers, start_item, end_item, this.context.searchDetail, this.highlightDataSet);

			//create offer_data for maps view
			this.map_results = ResultSetFactory.buildMapResults(this.all_offers, start_item, end_item);
		}
		else{
			this.show_pagination = false;
			results = (
					<MessageSharePartial>
						Es wurden keine Ergebnisse für diese Suche gefunden. <br/>
						Versuche es mit einer neuen Suche oder setze die Filter zurück.
					</MessageSharePartial>
				);
		}

		return results;
	}

	highlightDataSet(id){
		this.setState({
			zoomToID: id
		});
	}

	render() {

		let search_results = this.createSearchResults(this.props.results);
		let itemsPerPageOption = [
			{ value: 5, label: '5' },
			{ value: 10, label: '10' },
			{ value: 25, label: '25' }
		];

		let pagination = null;
		if(this.show_pagination){
			pagination = (
					<div className="pagination-wrapper">
						<div className="pagination-header">
							<h3 className="pagination-title">Ergebnisse pro Seite</h3>
							<Select
								className="pagination-select"
								classNamePrefix="ps"
								options={itemsPerPageOption}
								value={this.state.itemsCountPerPage}
								isMulti={false}
								name="select-pager"
								onChange={options => {this.handleItemsPerPage(options)}} />
						</div>

						<Pagination
							activePage={this.state.activePage}
							itemsCountPerPage={this.state.itemsCountPerPage.value}
							totalItemsCount={this.state.totalItemsCount}
							pageRangeDisplayed={15}
							onChange={this.handlePageChange}
							itemClassFirst="pageFirst"
							itemClassPrev="pagePrev"
							itemClassNext="pageNext"
							itemClassLast="pageLast"
							prevPageText="‹"
							nextPageText="›"
						></Pagination>
					</div>
				);
		}

		let active_filters = this.context.getActiveFiltersAsJSX();
		let active_filters_content;
		if(active_filters.length)
		{
			active_filters_content = <p className="active_filters"><strong>Filter:</strong>{active_filters}</p>
		}

		let reset_btn = null;
		if(this.context.checkFiltersActive())
		{
			reset_btn = <button className="resetBtn" onClick={()=>{ this.resetAllFilters() }}>Filterung zurücksetzen</button>;
		}

		return(

			<div className="search-results">
				<header className="search-results-header">
					<h2 className="title">Ergebnisse Ihrer Suche:</h2>
					{active_filters_content}
					<button className="backBtn" onClick={()=>{this.props.history.goBack()}}>&lt;&lt; zurück</button>
					{reset_btn}
				</header>
				<div name="scroll_start"></div>
				{ pagination }

				<SearchResultsWithMap
					api_results={this.map_results}
					zoomToID={this.state.zoomToID}
					>
					{ search_results }
				</SearchResultsWithMap>
				{ pagination }
			</div>
		);
	}
}
SearchResults.contextType = AppContext;

export default withRouter(SearchResults);
