import React, {Component} from 'react';
import Config from "../../Config";

import {AppContext} from '../../common/app/AppContext';

import ConfigCards from '../../ConfigCards';
import CreateSearchCardsWrapper from '../../common/components/CreateSearchCardsWrapper';


/**
 * This is the home view
 *
 * @class      ViewHome (name)
 */
class ViewHome extends Component {

    render() {

        this.context.setHeaderImg(Config.ImgHeaderHauptbild);

        return (

            <div className="view-home">
                <CreateSearchCardsWrapper
                    title="Frühe Hilfen in Stuttgart"
                    description="Informationen für Familien in den ersten Lebensjahren"
                    p_wrapperconfigcards={ConfigCards.CARDS_HOME}
                />
            </div>
        );
    }
}

ViewHome.contextType = AppContext;
export default ViewHome;
