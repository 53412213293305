import React from 'react';

/**
 * This is the context for all components, which makes it possible to call these functions from everywhere in the app without passing them down.
 */
export const AppContext = React.createContext({
	//Filter für Detailssuche, filters (mit options)
	filters: {},
	//Suche von Kacheln
	context_filters_or:  [],
	//gehört zu der Detailssuche
	location_filter: null,
	//exptra Filter für Datum, wird bei jede neue Suche zusätzlich abgefragt
	filterJoin_date: null,
	//extra Filter für Regionsuche, wird bei jede neue Suche zusätzlich abgefragt
	filterJoin_district: {},

	search_results: '',
	searchdetail_results: '',
	provider_results: '',
	// quicksearch_results: '',

	loader_active: null,
	header_img: null,

	//ok
	getActiveFiltersAsJSX: () => {},
	//ok
	checkFiltersActive: () => {},

	//can the next 2 functions be combined into one?
	//rebuild with promises
	getDetailsFromApi: () => {},
	//rebuild with promises
	getProviderDetailsFromApi: () => {
		console.error('DEV-ERROR: getProviderDetailsFromApi - muss implementiert werden.');
	},

	//rewrite with
	//better: create function in SearchFormEnhanced, that prepares the data for the filter
	//alternative: prepare in app and return to form
	callApiSearchResultsFromDetails: () => {},

	//rewrite
	//can these 3 functions be combined into one?
	callApiSearcResultshFromCardFiltersOr: () => {},

	//rewrite
	//called in: SearchResults
	callApiSearchResultsWithoutAllFilters: () => {},

	//rewrite
	//called in: SearchFormEnhanced
	callApiSearchWithCurFilters: () => {},

	//rebuild with promise
	resetAllFilters: () => {},

	//refactor with goToDetail
	searchDetail: () => {},
	//refactor with goToProvider
	searchDetailProvider: () => {},


	font_resize: null,
	high_contrast: null,
	switchContrast: () => {},
	setFontResize: () => {},

	//can we create a single function with this functionality
	setMainTextfilter: () => {},
	getMainTextfilter: () => {},
	getMainDatefilter: () => {},

	//can we create a single function with this functionality
	setMainFilterDateAndSearch: () => {},
	setKommunefilterAndSearch: () => {},

	filtersLoaded: () => {},
	setHeaderImg: () => {}

	/***************************************************************************************/
	// 	BASIS_T & MENDEN FUNCTIONS
	/***************************************************************************************/

	// resetAllFiltersAndSearch: () => {},
	// resetAllFiltersAndQuickSearch: () => {},

	// resetFilter: () => {},

	// resetFilterWithID: () => {},

	// searchText: () => {},
	// searchLast: () => {},

	// toggleFilterAndSearch: () => {},

	// setDateFilter: () => {},
	// setDateFilterAndQuickSearch: () => {},
	// setFilterAndQuickSearch: () => {},
	// setFilterAndSearch: () => {},
	// setMultipleFilters: () => {},

	// toggleFilterAndQuickSearch: () => {},
	// quickSearch: () => {},


});
