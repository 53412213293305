import React from 'react';
import Config from '../../Config';
import ScssVariables from '../../ConfigScss.scss';
import DateFilter from '../../common/model/DateFilter';
import SvgIcons from "../../common/components/SvgIcons.js";

/**
 * Has only static classes, that allow the creation of ResultSet components.
 * Also filters the API resuls for the MapResults
 *
 * @class      ResultSetComponents (name)
 */
export default class ResultSetComponents{


    /**
    * @pDateDatasetPublish  z.B: "30.05.2020"
    */
    static getElementNewDatasetIcon(pDateDatasetPublish){
      let elemNewDatasetIcon = '';
      if(pDateDatasetPublish && pDateDatasetPublish !== null){
        let dateForOfferNew = this.getDateDifference(new Date(), Config.DATASET_PUBLISHED_DAYS_AGO);
        let datePublished = DateFilter.parseDate(pDateDatasetPublish);
        //datePublished = date_format(datePublished, "dd.mm.yyyy");
        //<img className="cNewDataset" src={Config.IconResultSetNeu} alt="neu"></img>
        if(datePublished.getTime() > dateForOfferNew.getTime()){
          elemNewDatasetIcon = (<SvgIcons className="cNewDataset" p_IconKey={Config.IconResultSetNeu} p_Tooltip="Neu" width="40" fill={ScssVariables.iconSvgNeu_color} />);
        }
      }else{
        console.error("pDateDatasetPublish is leer");
      }
      return elemNewDatasetIcon;
    }
    /**
    * pDateStart: z.B. 'new Date()'
    * pDays: z.B. '-28' (Tage)
    */
    static getDateDifference (pDateStart, pDays){
    	let dateForOfferNew = pDateStart;
      dateForOfferNew.setDate(dateForOfferNew.getDate() + pDays);
    	// let date_format = require('dateformat');
    	// let dateFromNew = date_format(dateForOfferNew, "dd.mm.yyyy");
    	return dateForOfferNew;
    }

}
