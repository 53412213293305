import React, { Component } from 'react';
import Mailto from 'react-protected-mailto';

class MerkmalText extends Component {

	//https://stackoverflow.com/questions/37684/how-to-replace-plain-urls-with-links
	linkify(inputText) {
		if(inputText !== window.undefined && inputText !== null){
			  var replacedText, replacePattern1, replacePattern2, replacePattern3;

		    //URLs starting with http://, https://, or ftp://
		    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
		    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

		    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

		    //Change email addresses to mailto:: links.
		    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
		    return replacedText;
		}
		return inputText;
	}

	//from https://stackoverflow.com/questions/11300906/check-if-a-string-starts-with-http-using-javascript
	getValidUrl(url = ''){
		let newUrl = window.decodeURIComponent(url);
		newUrl = newUrl.trim().replace(/\s/g, '');
		if (/^(:\/\/)/.test(newUrl)) {
			return `http${newUrl}`;
		}
		if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
			return `http://${newUrl}`;
		}
		return newUrl;
	};

	//also from stackoverflow
	validateEmail(email) {
		// eslint-disable-next-line
		var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	}

	render() {

		let text_data = this.props.text_data;

		let text_arr = [];
		let merkmal_out = "";
		if(text_data && text_data.DataEditor)
		{
			switch(text_data.DataEditor)
			{
				case 'Freitext':
				case 'nur lesen':
				case 'mehrzeilig':
					let str;
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						//convert new lines and insert html >> is it really save?
						str = snippet.Values[0].Value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
						str = this.linkify(str);
						text_arr.push(<p key={index} className={text_data.DataEditor} dangerouslySetInnerHTML={{__html:str}}></p>);
					}
					merkmal_out = text_arr;
					break;
				case 'Datum':
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						text_arr.push(<p key={index} className={text_data.DataEditor}>{snippet.Values[0].Value}</p>);
					}
					merkmal_out = text_arr;
					break;
				case 'Link (www/eMail)':
					let link;
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						link = snippet.Values[0].Value;
						if(this.validateEmail(link))
						{
							text_arr.push(<p key={index}><Mailto email={link} /></p>);
						}
						else
						{
							text_arr.push(<p key={index} className="link_www" target="_blank"><a target="_blank" href={this.getValidUrl(link)}>{link}</a></p>);	
						}
					}
					merkmal_out = text_arr;
					break;
				case 'Liste (Benutzerdefiniert)':
				case 'Hierarchie (Benutzerdefiniert)':
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						text_arr.push(<li key={index}>{snippet.Values[0].Value}</li>);
					}
					merkmal_out = <ul>{text_arr}</ul>;
					break;
				default:
					merkmal_out = null;
					break;
			}
		}

		return merkmal_out;
	}
}

export default MerkmalText;