import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

import {AppContext} from './AppContext';
import Config from '../../Config';
import ConfigCards from '../../ConfigCards';
import Helper from '../../common/tools/Helper';
import FontResizer from './FontResizer';
import ContrastChanger from './ContrastChanger';
import SearchFormEnhanced from './SearchFormEnhanced';

import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    WhatsappIcon
} from "react-share";


/**
 * This class describes the header.
 *
 * @class      Header (name)
 * @prop        {boolean} showHero - hide || show the hero video
 * @prop        {object} heroData - {img, title, body}
 */
class Header extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();

        this.m_config = Config;
        this.m_logoHtml = (
            <div className="logocol">
                {/*<div className="row site-logo">*/}
                <div className="logo">
                    <a className="logobg" onClick={Helper.reload} title="zur Startseite"
                       style={{
                           background: "url(" + this.m_config.ImgHeaderLogoMandant + ")",
                           backgroundRepeat: 'no-repeat',
                           backgroundSize: 'contain',
                           backgroundPosition: 'left',
                           height: '100%'
                       }}
                    >
                        {this.m_config.TextHeaderToLogoMandantHtml}
                    </a>
                </div>
                {/*       </div>*/}
            </div>);
        this.hero_title = Config.HERO_TITLE;
        this.hero_body = Config.HERO_BODY;
    }

    searchFromLink(token) {

        //store setFilter function
        let setFilter = () => {
            this.context.setFilterAndSearch(Config.MAIN_TOPIC, token);
        }
        //reset all filters, then set new filter
        this.context.resetAllFilters(setFilter);
    }

    setBookmark() {
        window.alert('Bitte nutzen Sie die Lesezeichen-Funktion Ihres Browsers.');
    }


    createMenuItems(pConfigMenuLinks) {
        let menuLinks = [];
        let count = 0;
        menuLinks.push(<li key={count++} className="main_links"><a href="#"
                                                                   onClick={e => Helper.reload(e)}>Startseite</a></li>);
        for (let route of pConfigMenuLinks) {
            if (route.menu_untermenus && Object.keys(route.menu_untermenus).length > 0) {
                menuLinks.push(<li key={count++} className="links_title">{route.menu_title}</li>);
                let items = route.menu_untermenus;
                for (let item of items) {
                    if (item.menu_link_to) {
                        menuLinks.push(<li key={count++} data-toggle="collapse" data-target="#menu">
                            <NavLink to={item.menu_link_to}>{item.menu_title}</NavLink>
                        </li>);
                    } else if (item.menuhandel_filters) {
                        menuLinks.push(<li key={count++} data-toggle="collapse" data-target="#menu"
                        >
                            <NavLink to=''
                                     onClick={e => this.context.callApiSearcResultshFromCardFiltersOr(item.menuhandel_filters)}>
                                {item.menu_title}
                            </NavLink></li>);
                    }
                }
            } else if (route.menu_link_to) {
                menuLinks.push(<li key={count++} className="main_links" data-toggle="collapse" data-target="#menu">
                    <NavLink to={route.menu_link_to}>{route.menu_title}</NavLink></li>);
            }
        }
        return menuLinks;
//     return (<ul className="menuitems">
//     <li className="main_links"><a href="#" onClick={e => Helper.reload(e)}>Startseite</a></li>
//     <li className="links_title">Themenseiten</li>
//     {topic_links}
//     <li className="main_links" data-toggle="collapse" data-target="#menu"><NavLink to="/thema/familienfreundliche_orte">Familienfreundliche Orte</NavLink></li>
//     <li className="main_links" data-toggle="collapse" data-target="#menu"><NavLink to="/kontakt">Kontakt</NavLink></li>
//     <li className="main_links" data-toggle="collapse" data-target="#menu"><NavLink to="/ueber_uns">Über Uns</NavLink></li>
//     </ul>
// );
    }

    render() {

        let hero = null;
        let hero_url = null;
        let hero_style = null;
        //set img & title & body
        if (this.props.heroData) {
            let hero_data = this.props.heroData;
            if (hero_data.img) {
                hero_url = this.props.heroData.img;
            }
            if (hero_data.title) {
                this.hero_title = hero_data.title;
            }
            if (hero_data.body) {
                this.hero_body = Helper.StringToHtml(hero_data.body);
            }
        }
        if (!hero_url) {
            hero_url = Config.ImgHeaderHauptbild;
        }

        let letSearchFormEnhanced = '';
        if (this.props.p_showSearchFormEnhanced == null || this.props.p_showSearchFormEnhanced) {
            letSearchFormEnhanced = (<SearchFormEnhanced/>);
        }

        if (this.props.showHero == null || this.props.showHero) {
            hero = (
                <div className="herorow">
                    <div className="titlecol">
                        <h1>
                            {this.hero_title}
                        </h1>
                        <h2>
                            {this.hero_body}
                        </h2>
                    </div>
                    <div className="herocol">
                        <img className="hero-img" src={hero_url}/>
                        <div className="cSearchContainer">
                            <div className="cSearchBackground"/>
                            <div className="cSearchFormInnerContainer">
                                <div className="cSearchFormLogo">
                                    <a onClick={Helper.reload} title="zur Startseite"
                                       style={{
                                           background: "url(" + this.m_config.ImgHeaderLogoMandantSearchForm + ")",
                                           backgroundRepeat: 'no-repeat',
                                           backgroundSize: 'contain',
                                           backgroundPosition: 'left'
                                       }}
                                    />
                                </div>
                                {letSearchFormEnhanced}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        let menuitems = this.createMenuItems(ConfigCards.MENU_LINKS_ROUTE);
        let header_class = this.props.searchNavTop ? ' search-nav-top' : '';

        return (

            <header className={"site-header" + header_class} ref={this.ref}>
                {/*diese class container kommt von Bootstrap */}
                <div className="container">
                    <div className="menuwrapper">
                        <div className="menurow collapse" id="menu">
                            <div className="menucol">
                                <div className="menucloser"><i className="fa fa-times navbar-toggler"
                                                               data-toggle="collapse" data-target="#menu"></i></div>
                                <h4 className="title">Menu-Titel</h4>
                                <ul className="menuitems">
                                    {menuitems}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row openingtext">
                        <div className="col d-flex justify-content-start site-opening">
                            <div className="row introrow">
                                {this.m_logoHtml}
                            </div>
                        </div>

                        <div className="justify-content-end site-menus">
                            <div className="desktopfunctionscol">

                                <ContrastChanger contrast_mode={this.context.high_contrast}>Kontrast</ContrastChanger>
                                <FontResizer components={Config.FONT_RESIZE_COMPONENTS}
                                             resize={this.context.font_resize}/>
                                <button onClick={() => window.print()}><i className="fa fa-print"></i></button>
                                <button onClick={() => this.setBookmark()}><i className="fa fa-bookmark"></i></button>
                                <button className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><i className="fa fa-share-alt"></i></button>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <EmailShareButton url={window.location.href}><EmailIcon size={24}
                                                                                            round={true}/></EmailShareButton>
                                    <FacebookShareButton url={window.location.href}><FacebookIcon size={24}
                                                                                                  round={true}/></FacebookShareButton>
                                    <WhatsappShareButton url={window.location.href}><WhatsappIcon size={24}
                                                                                                  round={true}/></WhatsappShareButton>
                                </div>
                                <button><i className="fa fa-lock"></i></button>

                            </div>
                            <div className="mainmenucol">
                                <nav className="navbar justify-content-end">
                                    <div className="navbar-header">
                                        <button type="button" className="navbar-toggler collapsed"
                                                data-toggle="collapse" data-target="#menu" aria-expanded="false">
                                            <span className="sr-only">Toggle navigation</span>
                                            <i className="fa fa-bars"></i>
                                        </button>
                                    </div>
                                </nav>
                                <div className="text">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* den Container extra, um das Hintergrund boxed(full) zu erlauben */}
                <div className="cContainerHero">
                    {hero}
                </div>
            </header>

        );
    }
}

Header.contextType = AppContext;
export default Header;
