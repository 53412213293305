import React from 'react';
import {AppContext} from '../app/AppContext';

/**
 * @prop 	{int} filter_id - The filter id to get data from the API
 * @prop 	{string} headline - a headline
 * @prop 	{jsx} children
 */
export default class SearchListBox extends React.Component {

	constructor(props){
		super(props);

		this.items = null;
	}

	searchTopic(option_id){
		let filter = [{[this.props.filter_id]: [option_id]}];

		//todo: rebuild with promise
		this.context.callApiSearcResultshFromCardFiltersOr(filter);
	}

	createItemLinks(){
		let items_filter = this.context.filters[this.props.filter_id];
		let option;
		this.items = [];

		if(items_filter){
			for(let id in items_filter.options)
			{
				option = items_filter.options[id];
				this.items.push(<span className="list-item link" key={id} onClick={e => {this.searchTopic(id)}}>{option.value}</span>);
			}
		}
	}

	render() {
		this.createItemLinks();
		let cn = this.props.className ? ' ' + this.props.className : '';

		return (
			<div className={"search-list-box" + cn}>
				<div className="wrapper">
					<h2>{this.props.headline}</h2>
					<div className="text-wrapper">
						{this.props.children}
					</div>
					<section className="list-wrapper">
						{this.items}
					</section>
				</div>
			</div>
		);
	}
}
SearchListBox.contextType = AppContext;
