import React from 'react';
import AppBase from "../../common/app/AppBase";
import Tracker from "../../common/model/Tracker";
import Config from "../../Config";
import {AppContext} from "../../common/app/AppContext";
import {Switch} from "react-router-dom";
import RouteWrapper from "../../common/structure/RouteWrapper";
import ViewHome from "./ViewHome";
import ViewResults from "./ViewResults";
import ViewDetail from "./ViewDetail";
import ViewProvider from "./ViewProvider";
import ViewThema from "./ViewThema";
import LayoutDefault from "./LayoutDefault";
import View404 from "./View404";

import ImgHeaderCards from "./media/header/header_cards.jpg";
import ViewStaticSchwangerschaft from "./ViewStaticSchwangerschaft";
import ViewStaticNews from "./ViewStaticNews";

export default class App extends AppBase {

    constructor(props) {
        super(props);
    }

    render(){

        return (
            <div id="app-wrapper">
                {/*<BusyAnimationSharePartial loading={this.state.loader_active}></BusyAnimationSharePartial>*/}
                <Tracker active={Config.TRACKER.active} />
                <AppContext.Provider value={this.state}>
                    {/*redirect_to_home */}
                    {this.state.redirect}

                    {/*Todo: Watermark show?*/}
                    <Switch>
                        <RouteWrapper path="/" exact component={ViewHome} cssClass={"wrapper-home"} layout={LayoutDefault} />
                        <RouteWrapper path="/detail/:id" component={ViewDetail} cssClass={"wrapper-detail"} layout={LayoutDefault} />
                        <RouteWrapper path="/detailorganisation/:id" component={ViewProvider} cssClass={"wrapper-organisation"} layout={LayoutDefault} />
                        <RouteWrapper path="/thema/:topic" component={ViewThema} cssClass={"wrapper-thema"} layout={LayoutDefault} />
                        <RouteWrapper path="/ergebnisse/" exact component={ViewResults} cssClass={"wrapper-results"} layout={LayoutDefault} />
                        <RouteWrapper path="/ergebnisse/:page_num" component={ViewResults} cssClass={"wrapper-results"} layout={LayoutDefault} />

                        <RouteWrapper path="/schwangerschaftsvorsorge" component={ViewStaticSchwangerschaft} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/news" component={ViewStaticNews} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        {/*<Route path="/preview/" component={ViewPreview} />*/}
                        {/*<Route path="/ueber_uns/" component={ViewUeberUns} />*/}
                        {/*<RouteWrapper path="/kontakt/" component={ViewKontakt} layout={LayoutDefault} />*/}
                        {/*<RouteWrapper path="/impressum/" component={ViewImpressum} layout={LayoutDefault} />*/}
                        {/*<RouteWrapper path="/datenschutz/" component={ViewDatenschutz} layout={LayoutDefault}/>*/}
                        <RouteWrapper component={View404} cssClass={"wrapper-404"} layout={LayoutDefault} />
                    </Switch>
                </AppContext.Provider>
            </div>
        );
    }
}
