import React, { Component } from 'react';
import MerkmalZahl from './MerkmalZahl';
import MerkmalText from './MerkmalText';
import MerkmalWertebereich from './MerkmalWertebereich';
import MerkmalJaNein from './MerkmalJaNein';
import MerkmalAdresse from './MerkmalAdresse';
import MerkmalDokument from './MerkmalDokument';
import Helper from '../tools/Helper';

/**
 * Separates the different "merkmale" by DataType and call the matching components for rendering
 *
 * @class      Merkmal (name)
 * @prop 		{json} merkmal_data - API result data for one merkmal
 */
class Merkmal extends Component {

	render() {

		let out = null;
		let merkmal_data = this.props.merkmal_data;
		let merkmal = {};
		merkmal.label = merkmal_data.Label;

		//cut out "(FP) "
		merkmal.label = Helper.cutStr(merkmal.label, '(FP) ');
		
		let classes = ['merkmal', 'row', 'merkmal-label-' + Helper.makeSafeForCSS(merkmal.label)]

		switch(merkmal_data.DataType){
			case 'Text':
				merkmal.body = <MerkmalText text_data={merkmal_data}></MerkmalText>;
				classes.push('merkmal-text');
				break;
			case 'Zahl':
				merkmal.body = <MerkmalZahl zahl_data={merkmal_data}></MerkmalZahl>;
				classes.push('merkmal-zahl');
				break;
			case 'Wertebereich':
				merkmal.body = <MerkmalWertebereich wertebereich_data={merkmal_data}></MerkmalWertebereich>;
				classes.push('merkmal-wertebereich');
				break;
			case 'JaNein':
				merkmal.body = <MerkmalJaNein bool_data={merkmal_data}></MerkmalJaNein>;
				classes.push('merkmal-janein');
				break;
			case 'Adresse':
				merkmal.body = <MerkmalAdresse address_data={merkmal_data}></MerkmalAdresse>;
				classes.push('merkmal-adresse');
				break;
			case 'Dokument':
				merkmal.body = <MerkmalDokument doc_data={merkmal_data}></MerkmalDokument>;
				classes.push('merkmal-dokument');
				break;
			default:
				merkmal.body = null;
				break;
		}

		let class_name = classes.join(' ');
	
		if(merkmal_data.DataType === 'Dokument')
		{
			out = (
					<div className={class_name}>
						{merkmal.body}
					</div>					
				);
		}
		else{
			out = (
					<div className={class_name}>
						<div className="col-md-5 col-xs-12 merkmal-col label">
							<h4>{merkmal.label}</h4>
						</div>
						<div className="col-md-7 col-xs-12 merkmal-col body">
							{merkmal.body}	
						</div>
					</div>
			    );			
		}

		return out;		
	}
}

export default Merkmal;