import cloneDeep from 'lodash/cloneDeep';
import SearchFilter from './SearchFilter';
import SearchFilterOption from './SearchFilterOption';

import Helper from '../tools/Helper';

/**
* Statische Klasse für die Anpassung von Filtern
*/
export default class HelperFilter {

	/**
	* gibt den Filter mit den selectierten Optionen vom ersten Level zurück
	* @prop 		{SearchFilterOption} pFilter - see this.context.filters[Config.FILTER_IDS['slug']]
	*/
	static selectFirstLevelFromSearchFilterOption(pSearchFilterOption){
		if(pSearchFilterOption){
			pSearchFilterOption.options = this.selectFirstLevelFromOptions(pSearchFilterOption.options);
		}
		return pSearchFilterOption;
	}
	/** Model SearchFilterOption*/
	static selectFirstLevelFromOptions(pOptions){
		let optionsResult = {};
		if(typeof(pOptions) !== "undefined")
		{
			for(let option_id of Object.keys(pOptions))
			{
				let option = pOptions[option_id];
				if(option.position.split(".").length  === 1){
					optionsResult[option_id]=option;
				}
			}
			return optionsResult;
		}
	}
	/**List Model from Server*/
	static selectFirstLevelFromServerHierarchie(pOptions){
		let optionsResult = {};
		if(typeof(pOptions) !== "undefined")
		{
			let result = pOptions.filter(option => option.Position.split(".").length  === 1);
			return result;
		}
	}

	static removeOptionsByValue(options, val){
		let new_options = cloneDeep(options);
		for(let key in new_options)
		{
			if(new_options[key].value == val)
			{
				delete new_options[key];
			}
		}

		return new_options;
	}

	/*
	* pFilterApi
	**/
	static convertApiItemsToSearchWithOptions(pConfigFilterIds, pConfigFilterIdsSlug, pFilterApi){
		let options = this.convertApiItemsToSearchOptions(pFilterApi);
		if(options && options.length)
		{
			let filterGuid = pConfigFilterIds[pConfigFilterIdsSlug];
			return new SearchFilter(filterGuid, pConfigFilterIdsSlug, options);
		}
		return null;
	}
	static convertApiItemsToSearchOptions(pFilterApi){
		//let options = [];
		let options = {};
		if(pFilterApi && pFilterApi.length)
		{
			for(const filter_data of pFilterApi)
			{
				let option = new SearchFilterOption(filter_data.Token, filter_data.Designation, filter_data.Position);
				options[option.ID] = option;
			}
			return options;
		}
		return null;
	}

	/* konvertiert FilterOptionen to label-value SelectOptionen  **/
	static convertFilterWithOptionsToSelect(pFilterWithOptions){
		let options = [];
		if(pFilterWithOptions)
		{
			for(let option_id in pFilterWithOptions.options)
			{
				let option = pFilterWithOptions.options[option_id];
				options.push({
						value: option.ID,
						label: option.value
				});
			}
		}
		return options;
	}
	static convertOptionsToSelect(pFilterOptions){
		if(pFilterOptions && Object.keys(pFilterOptions).length > 0)
		{
			let options = [];
			for(let option_id in pFilterOptions)
			{
				let option = pFilterOptions[option_id];
				options.push({
						value: option.ID,
						label: option.value
				});
			}
			return options;
		}
		return null;
	}

	static getOptionsActiveFromSearchFilter(pSearchFilterWithOptions){
		let optionsAsArray = Object.values(pSearchFilterWithOptions.options);
		return optionsAsArray.filter(option => option.active);
	}	
}
