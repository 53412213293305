import React, {Component} from 'react';
import Header from "./Header";
import Footer from "./Footer";
import AddWatermarkSharePartial from "../../common/components/AddWatermarkSharePartial";
import SearchListBoxes from "./SearchListBoxes";
import {AppContext} from "../../common/app/AppContext";

/**
 * This is the main template component and renders the Header + Footer and the @children in between.
 *
 * @class      LayoutDefault (name)
 * @prop        {object} heroData - {img, title, body}
 */
class LayoutDefault extends Component {

    static defaultProps = {
        searchNavTop: true,
        p_showSearchFormEnhanced: true
    }

    render() {

        let addWatermark = '';
        if (this.props.p_addwatermark) {
            addWatermark = (<AddWatermarkSharePartial/>);
        }

        return (

            <div id="layout-default">
                {addWatermark}

                <Header
                    heroData={this.props.heroData}
                    searchNavTop={this.props.searchNavTop}
                    p_showSearchFormEnhanced={this.props.p_showSearchFormEnhanced}
                />

                <main>
                    <div className={this.props.cssClass}>
                        <div className="content">
                            {this.props.children}
                        </div>
                    </div>
                </main>

                <SearchListBoxes />

                <Footer />

            </div>

        );
    }
}
LayoutDefault.contextType = AppContext;
export default LayoutDefault;