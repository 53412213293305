import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {scroller} from 'react-scroll';
import cloneDeep from 'lodash/cloneDeep';

import ConfigCards from '../../ConfigCards';

import {AppContext} from '../../common/app/AppContext';
import CreateSearchCardsWrapper from '../../common/components/CreateSearchCardsWrapper';

import MessageSharePartial from '../../common/components/MessageSharePartial';

import imgPapa from './media/static/papa_mit_kids.jpg';


/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewStaticSchwangerschaft (name)
 */
class ViewStaticSchwangerschaft extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount(prevProps, prevState) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    handleAllResults(e){
        e.preventDefault();
        this.context.callApiSearcResultshFromCardFiltersOr([]);
    }

    render() {
        let out = null;

        //get data for cards
        let page_data = cloneDeep(ConfigCards.CARDS_LEVEL_ONE['schwangerschaft']);

        //delete first element
        delete page_data.cardhandel_searchcards["1"];

        let title = "";
        let description = "";

        //get page data
        if (page_data) {

            title = page_data.cardheadertitle;
            description = page_data.cardheaderbody;

            let siteContent = '';
            let search_cards = page_data.cardhandel_searchcards;

            if (page_data.message)
            {
                siteContent = <MessageSharePartial> {page_data.message} </MessageSharePartial>;
            }
            else if (search_cards && Object.keys(search_cards).length > 0)
            {
                siteContent = (
                    <CreateSearchCardsWrapper
                        p_wrapperconfigcards={search_cards}
                    />
                );

            }

            //create page
            out = (

                <div className={"view-static-schwangerschaft"} name="scroll_start">
                    <div className={"site-title"}>
                        <h1>Schwangerschaft &amp; Geburt</h1>
                    </div>

                    <section className={"content-groups"}>
                        <header>
                            <img src={imgPapa} alt="Papa mit Kids" className={"img-fluid"}/>
                            <p className={"img-subtitle"}>Schwangerschaftsvorsorge</p>
                            <p className={"header-body"}>
                                Die Schwangerschaftsvorsorge oder auch -betreuung ist eine Gesundheitsfürsorge. In der Schwangerschaft werden Schwangere von Frauenärztinnen/Frauenärzten oder Hebammen/Entbindungspflegern untersucht und beraten. Sie beantworten alle wichtigen Fragen.
                            </p>
                        </header>

                        <article className={"group"}>
                            <h2 className={"group-title"}>
                                Frauenärtzinnen/Frauenärzte
                            </h2>
                            <p className={"intro"}>
                                Frauenärztinnen und Frauenärzte sind speziell für Frauen da. Sie betreuen Frauen,....Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                            </p>
                            <p>
                                <a href="detailorganisation/bd014c90-b95c-48d0-8427-5447d7c2e76d">Städtische Beratungsstelle für  Schwangerschaftsfragen und Schwangerschaftskonflikte</a><br/>
                                <a href="detailorganisation/bd014c90-b95c-48d0-8427-5447d7c2e76d">Städtische Beratungsstelle für  Schwangerschaftsfragen und Schwangerschaftskonflikte</a><br/>
                                <a href="detailorganisation/bd014c90-b95c-48d0-8427-5447d7c2e76d">Städtische Beratungsstelle für  Schwangerschaftsfragen und Schwangerschaftskonflikte</a>
                            </p>
                            <p>
                                <a href="#" onClick={e => this.handleAllResults(e)}>&gt;&gt; Alle Ergebnisse</a>
                            </p>
                        </article>

                        <article className={"group"}>
                            <h2 className={"group-title"}>
                                Hebammen
                            </h2>
                            <p className={"intro"}>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                            </p>
                            <p>
                                <a href="#" onClick={e => this.handleAllResults(e)}>&gt;&gt; Alle Ergebnisse</a>
                            </p>
                        </article>

                        <article className={"group"}>
                            <h2 className={"group-title"}>
                                Familienhebammen
                            </h2>
                            <p className={"intro"}>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies, purus lectus malesuada libero, sit amet commodo magna eros quis urna. Nunc viverra imperdiet enim. Fusce est. Vivamus a tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                            </p>
                            <p>
                                <a href="#" onClick={e => this.handleAllResults(e)}>&gt;&gt; Alle Ergebnisse</a>
                            </p>
                        </article>


                    </section>

                    <div className="view-thema">
                        {siteContent}
                    </div>
                </div>

            );
        } else {
            //redirect to 404
            out = <Redirect to="/404"/>;
        }

        return out;
    }
}

ViewStaticSchwangerschaft.contextType = AppContext;
export default ViewStaticSchwangerschaft;
