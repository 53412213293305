import React, { Component } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import {AppContext} from './AppContext';
import FilterDropDownImmediately from './FilterDropDownImmediately';
import Config from '../../Config';
import HelperFilter from '../../common/model/HelperFilter';


/**
 * This component renders the search form and is build as a controlled form.
 * @see https://reactjs.org/docs/forms.html
 * All values are stored in this.state
 * The form data is then submited via the AppContext.
 *
 *
 * @class      SearchFormEnhanced (name)
 */
class SearchFormEnhanced extends Component {

  constructor(props) {
    super(props);
    //die Variablennamen in State entsprechen den Namen(slug) von FILTER_IDS
    this.state = {
                refresh: false,
                //search_value: '', // Textsuche wird in AppContext direkt verwaltet
                location: '',
                radius: '',
                audience: '',
                topic: '',
                offer_types: '',
                // Kommunesuche  wird in AppContext direkt verwaltet
                // im state brauchen wir nur
                // district: '',
                // date: [null, null]
  };

    // This binding is necessary to make `this` work in the callback
    this.submitFormSearchDetails = this.submitFormSearchDetails.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFilterDatePicker = this.handleFilterDatePicker.bind(this);
    // war für den einfachauswahl jetzt rausgenommen:
    //this.handleSubmitSelectCommune = this.handleSubmitSelectCommune.bind(this);
    this.handleStartApiSearchWithMainFilter = this.handleStartApiSearchWithMainFilter.bind(this);
    this.handleKeyPressedSuchbegriff = this.handleKeyPressedSuchbegriff.bind(this);

    this.form_filters = {};


    this.m_dateformat = "DD.MM.YYYY";

    this.m_options_topic = '';
    this.m_options_audience = '';
    this.m_options_offer_types = '';
    this.m_options_commune = '';

  }
  refreshThisComponent(){
    this.setState({refresh: !this.state.refresh});
  }
  initFilters(){
    this.m_options_topic = this.convertApiItemsToSearchOptions('topic');
    this.m_options_audience = this.convertApiItemsToSearchOptions('audience');
    this.m_options_offer_types = this.convertApiItemsToSearchOptions('offer_types');
    //hier werden nur die Options erstens Levels für die Anzeige selectiert
    this.m_options_commune = this.convertApiItemsToSearchOptions('district');
  }



  /**DateFormat siehe this.m_dateformat*/
  convertStringToDatepicker(pDateStr){
    let dateParts = pDateStr.split(".");
    return new Date(parseInt(dateParts[2], 10), //Jahr
                    parseInt(dateParts[1], 10) - 1, // Careful, month starts at 0!
                    parseInt(dateParts[0], 10) //Day
                    );
  }
  convertApiItemsToSearchOptions(filter_name){

    let options = [];

		if(this.context.filters)
		{
			let filter;
			//get filter from context via ID & get ID from Config
      switch(filter_name){
        case 'offer_types':
          filter = this.context.filters[Config.FILTER_OFFER_TYPES];
          break;
        case 'district':
          filter = this.context.filterJoin_district;//HelperFilter.selectFirstLevelFromSearchFilterOption(this.context.filters[Config.FILTER_IDS[filter_name]]);
          break;
        default:
          filter = this.context.filters[Config.FILTER_IDS[filter_name]];
          break;
      }
			if(filter)
			{
				for(let option_id in filter.options)
				{
					let option = filter.options[option_id];
		            options.push({
	            		value: option.ID,
	            		label: option.value
		            });
				}

			}
		}

    return options;
  }

  handleFilterDatePicker(pDatepicker){
    this.context.setMainFilterDateAndSearch(pDatepicker);
  }

  handleInput(event){
    switch(event.target.id){
      case 'input-search':
            this.context.setMainTextfilter(event.target.value);
            this.refreshThisComponent();
            break;
      case 'location-center':
            this.setState({location: event.target.value});
            break;
      case 'search-distance':
            //check for number with regex
            const re = /^[0-9\b]+$/;
            //accept empty OR test the regex AND make sure value is < 200
            if (event.target.value === ''){
              this.setState({radius: ''});
            }
            else if(re.test(event.target.value) && event.target.value < 200){
              this.setState({radius: parseInt(event.target.value)});
            }
            break;
    }
  }

  handleKeyPressedSuchbegriff(event){
    // enter has keyCode = 13, change it if you want to use another button
    if (event.keyCode == 13) {
      this.handleStartApiSearchWithMainFilter(event);
      return false;
    }
  }

	handleSelect(filter_slug, options, submit){
		//turn single option into array
		if(!Array.isArray(options))
		{
			let arr_temp = [];
			arr_temp.push(options);
			options = arr_temp;
		}
		//store filters in state
    if(submit){
      this.setState({
  			[filter_slug]: options
    		}, () => {
          // nachdem die state gesetzt ist, rufe die folgende Methode Herausforderungen
           this.context.callApiSearchResultsFromDetails(this.state);
        }
      );
    }else{
      this.setState({
        [filter_slug]: options
      });
    }
	}

  // war für den einfachauswahl jetzt rausgenommen:
  /** @pOptions: label + value*/
  // handleSubmitSelectCommune(pOptions){
  //   this.context.setKommunefilterAndSearch('district', pOptions);
  //   this.refreshThisComponent();
  // }

	resetForm(event){
		event.preventDefault();

		this.setState({
			location:  "",
			radius: "",
			audience: null,
			topic: null,
			offer_types: null,

			// district: null, wird nur über den Headerbutton automatisch zurückgesetzt
			// date: null, wird nur über den Headerbutton automatisch zurückgesetzt
		}/*, () => {this.submitFormSearchDetails(event)} direkt suchen*/);
	}
  /**
     * Submit form data to AppContext
     *
     * @param      {object}  event
     */
  submitFormSearchDetails(event) {
    if(event) event.preventDefault();
    this.context.callApiSearchResultsFromDetails(this.state);
  }



  /** Sichtbaren Filter im Header absenden */
  handleStartApiSearchWithMainFilter(event){
    this.context.callApiSearchWithCurFilters();
    event.preventDefault(); // sonst wird submitFormSearchDetails() aufgeruffen,,, warum?
  }

	render() {
    if( (!this.m_options_commune || this.m_options_commune.length === 0) && this.context.filtersLoaded()){
      this.initFilters();
    }

		let noOptionsMessage = () => {return "Keine Merkmale."};

    let curDateContext = this.context.getMainDatefilter();
    let curDateInput = [null,null];
    if(curDateContext){
      curDateInput = [
        this.convertStringToDatepicker(curDateContext.date_start),
        this.convertStringToDatepicker(curDateContext.date_end)
      ];
    }

    let textinput = this.context.getMainTextfilter();

		return(

			<nav className="SearchFormEnhanced">

					<div className="tab-content" id="searchContent">
						<div className="tab-pane fade show active" id="allgemeine-suche" role="tabpanel" aria-labelledby="allgemeine-suche-tab">
							<div className="fieldsets-main">

								<fieldset className="fs-search-keywords">
									<legend>Suchbegriffe</legend>
									<div className="keywords">
										<label className="" htmlFor="input-search"><i className="fa fa-search"></i></label>
										<input  className="" id="input-search" type="text"
                            placeholder="Suchbegriff/e"
                            value={textinput}
                            onChange={this.handleInput}
                            onKeyDown={this.handleKeyPressedSuchbegriff}
                            tabIndex="0"
                    />
									</div>
									<div className="submit">
										<label className="submit-label" htmlFor="search-plus">Suche starten</label>
										<button className="submit-button" onClick={(e) => this.handleStartApiSearchWithMainFilter(e)}><i className="fa fa-search"></i></button>
									</div>
								</fieldset>


								<div className="fs-wrapper">
									<fieldset className="fs-search-timespan">
										<legend>Zeitraum</legend>
										<div className="timespan-row">
											<div className="p-2 datepicker">
											    <DateRangePicker
											      calendarIcon={<i className="fa fa-calendar"></i>}
											      onChange={this.handleFilterDatePicker}
											      locale="de-DE"
											      value={curDateInput}
											      minDate={new Date()}
											    />
											</div>
										</div>
									</fieldset>

									<fieldset className="fs-search-town">
										<ul>
											<li className="nav-item">
                        <FilterDropDownImmediately
                              p_label="Kommune"
                              p_SearchFilter={this.context.filterJoin_district}
                        />
                        {/*p_FilterId={Config.FILTER_IDS['district']}*/}
											</li>
										</ul>
									</fieldset>

									<fieldset className="fs-search-extend-controls">
										<legend className="title">Formularsteuerung erweiterte Optionen</legend>
										<div className="extend-button">
											<div className="desc"></div>
											<button className="btn collapsed" type="button" data-toggle="collapse" data-target="#fieldsets-extended" aria-expanded="false" aria-controls="Erweiterte Suche">Detailsuche</button>
										</div>
									</fieldset>

								</div>
							</div>
							{/*<!--/fieldsets-main-->*/}

              <form onSubmit={this.submitFormSearchDetails}>
  							<div id="fieldsets-extended" className="collapse">

  								<div className="search-location">
  									<div className="title">Umkreis-Suche</div>
  									<fieldset className="fieldset-location">
  										<div className="row">
  											<div className="col input-group">
  												<label className="label-location-center" htmlFor="location-center">Zentrum</label>
  												<input className="input-location-center" id="location-center" type="text" placeholder="Ort/PLZ" value={this.state.location} onChange={this.handleInput} />
  											</div>
  											<div className="col input-group">
  												<div className="">
  													<label className="mr-2" htmlFor="search-distance">max. Entfernung</label>
  												</div>
  												<input className="input-search-distance" id="search-distance" type="text" placeholder="km Umkreis" value={this.state.radius} onChange={this.handleInput}/>
  											</div>
  										</div>
  									</fieldset>
  								</div>

  								<div className="search-constrict">
  									<div className="title">Suche einschränken</div>

  									<fieldset className="detailsearch">
  										<a className="" data-toggle="collapse" href="#constrict-1" role="button" aria-expanded="true" aria-controls="constrict-1">
  											<div className="col expander">Zielgruppe</div>
  										</a>
  										<div className="collapse show" id="constrict-1">
  											<Select className="option-select" classNamePrefix="os" options={this.m_options_audience} value={this.state.audience} isMulti={true} placeholder="Zielgruppe auswählen" noOptionsMessage={noOptionsMessage} name="select-audience" onChange={options => {this.handleSelect('audience', options)}} />
  										</div>
  									</fieldset>

  									<fieldset className="detailsearch">
  										<a className="" data-toggle="collapse" href="#constrict-2" role="button" aria-expanded="true" aria-controls="constrict-2">
  											<div className="col expander">Thema</div>
  										</a>
  										<div className="collapse show" id="constrict-2">
  											<Select className="option-select" classNamePrefix="os" options={this.m_options_topic} value={this.state.topic} isMulti={true} placeholder="Thema auswählen" noOptionsMessage={noOptionsMessage} name="select-theme" onChange={options => {this.handleSelect('topic', options)}} />
  										</div>
  									</fieldset>

  									<fieldset className="detailsearch">
  										<a className="" data-toggle="collapse" href="#constrict-3" role="button" aria-expanded="true" aria-controls="constrict-3">
  											<div className="col expander">Ich suche...</div>
  										</a>
  										<div className="collapse show" id="constrict-3">
  											<Select className="option-select" classNamePrefix="os" options={this.m_options_offer_types} value={this.state.offer_types} isMulti={true} placeholder="Angebot auswählen" noOptionsMessage={noOptionsMessage} name="select-theme" onChange={options => {this.handleSelect('offer_types', options)}} />
  										</div>
  									</fieldset>

  								</div>

  								<fieldset className="fieldset-controls">
  									<legend className="">Formularsteuerung</legend>
  									<div className="desc help">
  										<p>Starten Sie die Suche oder setzen Sie alle Filter zurück.</p>
  									</div>
  									<button className="button button-reset" type="reset" onClick={(e) => this.resetForm(e)}>Detailsuche zurücksetzen</button>
  									<button className="button button-submit" type="submit" data-toggle="collapse" data-target="#fieldsets-extended">Jetzt Suchen!</button>
  								</fieldset>
  							</div>
              </form>
							{/*<!--/fieldsets-ext-->*/}
						</div>
					</div>
			</nav>

		);
	}
}
//bind to AppContext
SearchFormEnhanced.contextType = AppContext;

export default SearchFormEnhanced;
