import React, { Component } from 'react';

import {AppContext} from '../../common/app/AppContext';
import SearchDetails from '../../common/components/SearchDetails';
import MessageSharePartial from '../../common/components/MessageSharePartial';


/**
 * Wrapper/View component for SearchDetails.
 *
 * @class      ViewDetail (name)
 */
class ViewDetail extends Component{
	constructor(props){
		super(props);

		this.state = {
			c_jsondata: null
		};

	}

	componentDidMount() {
		//search a dataset_id from the API
		//this.props.match is coming from the Router
		//@see App.js > render + @see https://reacttraining.com/react-router/web/api/match
		if(this.props.match && this.props.match.params.id){
				this.context.getDetailsFromApi(this.props.match.params.id);
		}
		// if calls ViewPreview
		else if(this.props.p_datasetOffer){
			this.setState({
				c_jsondata: this.props.p_datasetOffer
			});
		}
	}

	render(){
		let details = null;
		let bWatermarkShow = '';
		if(this.state.c_jsondata !== null && this.state.c_jsondata)
		{
			details = <SearchDetails dataset={this.state.c_jsondata[0]}/>
			bWatermarkShow = true;
		}else if(this.context.searchdetail_results){
			details = <SearchDetails dataset={this.context.searchdetail_results[0]}/>
		}
		else{
			details = <MessageSharePartial>Angebot wird geladen ...</MessageSharePartial>;
		}

		return(
				<div className="view-detail">
					<div className="row">
						{details}
					</div>
				</div>
		);
	}
}
ViewDetail.contextType = AppContext;
export default ViewDetail;
