import React, {Component} from 'react';
import {withRouter} from "react-router";
import {scroller} from 'react-scroll';

import Config from '../../Config';
import ResultHelper from '../../common/results/ResultHelper';

import Group from '../../common/results/Group';
import MapDetail from '../../common/components/MapDetail';
import MerkmalText from '../../common/results/MerkmalText';

/**
 * This component renders the search details
 *
 * @class      CreateDetailsProvider (name)
 * @prop        {json} dataset - api result set
 */
class CreateDetailsProvider extends Component {

    constructor(props) {
        super(props);
        this.ref_map = React.createRef();
        this.m_configResultIdAnbieters = Config.RESULT_IDS_PROVIDER;
    }

    componentDidMount() {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });

        window.addEventListener('scroll', this.listenToScroll);
    }

    render() {

        let offer, output;
        let dataset = this.props._datasetId;
        let groups = [];
        let counter = 0;
        let maps_view = null;
        let headerContent = null;

        if (dataset) {
            let ausnahmeMerkmalTypeIds = Config.DETAILVIEW_MERKMALTYPE_OFF_PROVIDER;
            console.log(dataset);

            //get groups
            // let merkmal_label;
            // let merkmal_data_value;
            let guidShortDescription = this.m_configResultIdAnbieters.short_description;

            //iterate through groups and render group
            for (let group_data of dataset.Groups) {
                //Kontaktdaten in header anzeigen
                if (Config.RESULT_IDS.contact_group && group_data.GroupId === Config.RESULT_IDS.contact_group) {
                    let merkmal_data = group_data.Merkmale;
                    headerContent =
                        (
                            <div className="summary">
                                <h4 className="summary-title">{group_data.GroupDesignation}</h4>
                                <table>
                                    <tbody>
                                    {merkmal_data.map((row, index) => (
                                        <tr key={index}>
                                            <td className="summery-label" key="td-1">{row.Label}</td>
                                            <td className="summery-value" key="td-2">{<MerkmalText
                                                text_data={row}></MerkmalText>}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )
                } else {
                    groups.push(
                        <Group group_data={group_data} key={'group-' + ++counter}
                               p_merkmaltypeid_off={ausnahmeMerkmalTypeIds}></Group>
                    );
                    //try to find shortdescription
                    if (!headerContent) {
                        let merkmal_data = ResultHelper.searchMerkmalId(group_data, guidShortDescription, true);
                        if (merkmal_data) {
                            headerContent = (
                                <div className="summary">
                                    <h4 className="summary-title-provider">Kurzbeschreibung</h4>
                                    <MerkmalText text_data={merkmal_data}></MerkmalText>
                                </div>
                            );
                        }
                    }
                }
            }

            //create maps view, when lat or lng != 0
            let latitude, longitude;

            latitude = parseFloat(dataset.Latitude);
            longitude = parseFloat(dataset.Longitude);

            if (latitude || longitude) {
                maps_view = (
                    <section className="maps mb-5" ref={this.ref_map}>
                        <MapDetail width="100%" height="400px" lat={latitude} lng={longitude}></MapDetail>
                    </section>
                );
            }


        }

        return (
            <div>
                <div className="eventinfo" name="scroll_start">
                    <header className="row information">
                        <div className="offer-name-col">
                            <div className="offer-name">
                                <h2 className="title">{dataset.LongName}</h2>
                                <span style={{display: 'none'}}>{dataset.DatasetId}</span>
                            </div>
                        </div>
                        {headerContent}
                    </header>
                    <article className="groups">
                        {groups}
                    </article>
                </div>
                {maps_view}
            </div>
        );

    }

}

export default withRouter(CreateDetailsProvider);
