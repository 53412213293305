import React from 'react';
import $ from 'jquery';
import {AppContext} from './AppContext';

// Example


/**
 * The ContrastChanger adds "contrast" class to the body.
 *
 * @class      ContrastChanger (name)
 * 
 */
export default class ContrastChanger extends React.Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.updateBody(this.props.contrast_mode);
	}

	componentDidUpdate(prevProps, prevState) {

		if(this.props.contrast_mode !== prevProps.contrast_mode)
		{
			this.updateBody(this.props.contrast_mode);
		}
	}

	updateBody(contrast_mode)
	{
		this.removeClass();

		//set classes if not 0
		if(contrast_mode)
		{
			$('body').addClass('high-contrast');
		}		
	}

	removeClass(){
		$('body').removeClass('high-contrast');	
	}

	render() {
		return(
			<div className="ContrastChanger">
				<button className="btn-contrast" onClick={e => this.context.switchContrast()}><i className="fa fa-adjust"></i>{this.props.children}</button>
			</div>
		);
	}
}
ContrastChanger.contextType = AppContext;
