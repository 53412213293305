import React, {Component} from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import RouteWrapper from '../../common/structure/RouteWrapper';

import Config from '../../Config';

// import BusyAnimationSharePartial from '../../common/components/BusyAnimationSharePartial';

import ApiConnector from '../../common/model/ApiConnector';
import DateFilter from '../../common/model/DateFilter';
import HelperFilter from '../../common/model/HelperFilter';
import LocationFilter from '../../common/model/LocationFilter';
import SearchFilter from '../../common/model/SearchFilter';
import SearchFilterOption from '../../common/model/SearchFilterOption';
import Tracker from '../../common/model/Tracker.js';

import {AppContext} from './AppContext';

import LayoutDefault from "../../sites/stuttgart/LayoutDefault";
import View404 from "../../sites/stuttgart/View404";
//the views and router for the specific site are implemented in the concrete App.js
//e.g. src/sites/stuttgart/App.js

// import console from 'console-suppress';
import promisifySetState from 'promisify-setstate';


/**
 * The main application class
 *
 * @class      App (name)
 */
class AppBase extends Component {

    /**
     * The constructor creates all bindings for the AppContext
     *
     * @see AppContext.js
     */
    constructor(props) {
        /* This is an abstract class providing all the basic functionality
         * Extend in /sites/$sitename/App.js
         */
        if (new.target === AppBase) {
            throw new TypeError("AppBase is abstract, please extend in sites/$sitename/App.js for specific implementation");
        }

        super(props);

        //the suppressors can be used to supress certain console logs
        // console.warn.suppress("href");
        // console.warn.suppress("simplebar");

        //This binding is necessary to make `this` work in the callback
        this.callApiSearchResultsFromDetails = this.callApiSearchResultsFromDetails.bind(this);
        this.callApiSearcResultshFromCardFiltersOr = this.callApiSearcResultshFromCardFiltersOr.bind(this);
        this.callApiSearchResultsWithoutAllFilters = this.callApiSearchResultsWithoutAllFilters.bind(this);
        this.callApiSearchWithCurFilters = this.callApiSearchWithCurFilters.bind(this);
        this.searchDetail = this.searchDetail.bind(this);
        this.searchDetailProvider = this.searchDetailProvider.bind(this);
        this.getDetailsFromApi = this.getDetailsFromApi.bind(this);
        this.getProviderDetailsFromApi = this.getProviderDetailsFromApi.bind(this);
        this.receiveSearchResultDetail = this.receiveSearchResultDetail.bind(this);
        this.receiveSearchResultWithOr = this.receiveSearchResultWithOr.bind(this);
        this.receiveProviderDetail = this.receiveProviderDetail.bind(this);
        this.receiveError = this.receiveError.bind(this);

        this.resetAllFilters = this.resetAllFilters.bind(this);
        this.getActiveFiltersAsJSX = this.getActiveFiltersAsJSX.bind(this);
        this.checkFiltersActive = this.checkFiltersActive.bind(this);

        this.setFontResize = this.setFontResize.bind(this);
        this.switchContrast = this.switchContrast.bind(this);

        //create default date filter
        this.default_date_filter = DateFilter.getDefault();

        this.m_mainTextfilter = "";
        this.setMainTextfilter = this.setMainTextfilter.bind(this);
        this.getMainTextfilter = this.getMainTextfilter.bind(this);
        this.getMainDatefilter = this.getMainDatefilter.bind(this);

        this.setMainFilterDateAndSearch = this.setMainFilterDateAndSearch.bind(this);
        this.toggleFilterDistrictAndSearch = this.toggleFilterDistrictAndSearch.bind(this);
        this.prCallApiSearchForResults = this.prCallApiSearchForResults.bind(this);
        this.filtersLoaded = this.filtersLoaded.bind(this);
        this.setHeaderImg = this.setHeaderImg.bind(this);

        this.state = {
            loader_active: false,
            header_img: null,
            last_search_str: "",

            //search context
            filterJoin_date: this.default_date_filter,
            filterJoin_district: {},

            location_filter: null,
            filters: {},
            context_filters_or: [],
            search_results: '',
            searchdetail_results: '',
            quicksearch_results: '',
            provider_results: '',
            redirect: null,
            resetAllFilters: this.resetAllFilters,
            getActiveFiltersAsJSX: this.getActiveFiltersAsJSX,
            checkFiltersActive: this.checkFiltersActive,
            searchDetail: this.searchDetail,
            searchDetailProvider: this.searchDetailProvider,
            getDetailsFromApi: this.getDetailsFromApi,
            getProviderDetailsFromApi: this.getProviderDetailsFromApi,

            font_resize: 0,
            setFontResize: this.setFontResize,
            high_contrast: false,
            switchContrast: this.switchContrast,

            callApiSearchResultsFromDetails: this.callApiSearchResultsFromDetails,
            callApiSearcResultshFromCardFiltersOr: this.callApiSearcResultshFromCardFiltersOr,
            callApiSearchResultsWithoutAllFilters: this.callApiSearchResultsWithoutAllFilters,
            callApiSearchWithCurFilters: this.callApiSearchWithCurFilters,

            setMainTextfilter: this.setMainTextfilter,
            getMainTextfilter: this.getMainTextfilter,
            getMainDatefilter: this.getMainDatefilter,

            setMainFilterDateAndSearch: this.setMainFilterDateAndSearch,
            toggleFilterDistrictAndSearch: this.toggleFilterDistrictAndSearch,
            setKommunefilterAndSearch: this.setKommunefilterAndSearch,
            prCallApiSearchForResults: this.prCallApiSearchForResults,
            filtersLoaded: this.filtersLoaded,
            setHeaderImg: this.setHeaderImg
        };

        console.log("location", window.location.hostname);
        console.log('Letzte Aktualisierung: ' + Config.LASTUPDATE);
        console.log('Version: ' + Config.VERSION);
        console.log('API URL: ' + Config.API_URL);
        console.log('Matomo Tracker active: ' + Config.TRACKER.active);

        if (!Config.DEBUG)
            console.log = function () {
        };
    }

    componentDidMount() {

        //wenn preview geladen wird, das Filterladen soll übersprungen werden
        if (window.location.href !== null && window.location.href.indexOf("/preview") >= 0) {
            //Die Vorschau ist unzuverlässig,
            //kann sein dass die große FilterDaten das Laden zerstören können?
            this.showLoader(false);
            return;
        }


        //create filters from API
        for (let slug in Config.FILTER_IDS) {
            this.createFilter(slug, Config.FILTER_IDS[slug]);
        }

        //get offer types
        this.createOfferTypesFilter();
    }

    componentDidUpdate(prevProps, prevState) {
        //stop redirect loop
        if (this.state.redirect) {
            this.setState({
                redirect: null
            });
        }
    }


    /**
     * Creates a filter.
     * Access the filter from class with connected AppContext like this:
     * this.context.filters[Config.FILTER_IDS[ id ]]
     * for id @see: Config.FILTER_IDS
     *
     * @param      {string}  slug - name of filter
     * @param      {string}  ID - id of filter (see Config.FILTER_IDS)
     */
    createFilter(slug, ID) {
        this.showLoader(true);
        ApiConnector.getListItems(ID, (api_data) => {
            console.log("createFilter(api_data) " + slug, api_data);

            let options = {};

            if (slug === 'district') {
                api_data = HelperFilter.selectFirstLevelFromServerHierarchie(api_data);
            }

            for (const filter_data of api_data) {
                let option = new SearchFilterOption(filter_data.Token, filter_data.Designation, filter_data.Position);
                options[option.ID] = option;
            }

            let filter = new SearchFilter(ID, slug, options);

            //don't modify state object, instead make a copy
            let newState = cloneDeep(this.state);
            switch (slug) {
                case 'district':
                    //district-Filter in join initialisieren
                    newState.filterJoin_district = filter;
                    break;
                default:
                    //district-Filter auch in filters initialisieren (filterDetails)
                    newState.filters[ID] = filter;
                    break;
            }


            //set new filter and hide loader if all filters are loaded
            this.setState(
                newState
                , () => {
                    if (this.filtersLoaded())
                        this.showLoader(false);
                }
            );
        });
    }

    /**
     * Checks if all filters are created and loaded.
     *
     * @return     {Boolean}  true if all filters are loaded
     */
    filtersLoaded() {
        //3+1
        let num_config_filters = Object.keys(Config.FILTER_IDS).length;
        if (Config.FILTER_OFFER_TYPES) {
            //OfferType filter muss auch initialisiert werden
            num_config_filters++;
        }

        let num_state_filters = Object.keys(this.state.filters).length;
        if (this.state.filterJoin_district && this.state.filterJoin_district.options && Object.keys(this.state.filterJoin_district.options).length > 0) {
            //Filter für die regionale Suche ist ausgelagert:
            num_state_filters++;
        }
        return (num_state_filters === num_config_filters);
    }

    /**
     * Gets the offer types from the API and builds a SearchFilter
     * Access the filter from class with connected AppContext like this:
     * this.context.filters[Config.FILTER_OFFER_TYPES]
     */
    createOfferTypesFilter() {
        this.showLoader(true);

        ApiConnector.getOfferTypes((api_data) => {

            console.log('Offer Types Api-Data:', api_data);

            let options = {};
            for (const filter_data of api_data) {
                let option = new SearchFilterOption(filter_data.Id, filter_data.Designation);
                options[option.ID] = option;
            }

            let filter = new SearchFilter(Config.FILTER_OFFER_TYPES, Config.FILTER_OFFER_TYPES, options);

            //don't modify state object, instead make a copy
            let new_filters = cloneDeep(this.state.filters);
            new_filters[Config.FILTER_OFFER_TYPES] = filter;
            //set new filter and hide loader if all filters are loaded
            this.setState({
                    filters: new_filters
                }, () => {
                    if (this.filtersLoaded())
                        this.showLoader(false);
                }
            );
        });
    }

    setMainTextfilter(pSearchtext) {
        this.m_mainTextfilter = pSearchtext;
    }

    /**dd.mm.yyyy*/
    setMainFilterDateAndSearch(pFilterDatepicker) {
        let new_filters = cloneDeep(this.state.filters);

        let newDatefilter = null;
        if (pFilterDatepicker && pFilterDatepicker[0] && pFilterDatepicker[1])
            newDatefilter = DateFilter.getFilterFromDates(pFilterDatepicker[0], pFilterDatepicker[1]);
        else
            newDatefilter = this.default_date_filter;

        this.setState({
                filters: new_filters,
                filterJoin_date: newDatefilter,
                search_results: null,
                redirect: <Redirect push to='/ergebnisse/'/>
            },
            // search results, execute after state change
            () => {
                this.prCallApiSearchForResults()
            }
        );
    }

    toggleFilterDistrictAndSearch(pDistrictSlugId, pDistrictOptionId) {

        let new_state = cloneDeep(this.state);
        let active = (this.state.filterJoin_district.options[pDistrictOptionId].active) ? false : true;
        new_state.filterJoin_district.options[pDistrictOptionId].active = active;

        new_state.search_results = null;
        new_state.redirect = <Redirect push to='/ergebnisse/'/>;

        this.setState(
            new_state,
            // search results, execute after state change
            () => {
                this.prCallApiSearchForResults()
            }
        );
    }

    callApiSearchWithCurFilters() {
        this.setState({
                search_results: null,
                redirect: <Redirect push to='/ergebnisse/'/>
            },
            // search results, execute after state change
            () => {
                this.prCallApiSearchForResults()
            }
        );
    }

    prCallApiSearchForResults() {

        //todo: let apiconnector build filters
        let filtersJoin = this.getCurJoinFilters();
        let newFilters = cloneDeep(this.state.filters);

        console.log('---- prCallApiSearchForResults');
        console.log("filterJoin", filtersJoin);
        console.log("newFilters", newFilters);

        let api_filters = this.createFilterForApiSearchFromStateValues(newFilters, filtersJoin);
        let result_api_filters = [];
        if (api_filters && api_filters.length > 0)
            result_api_filters.push(api_filters);
        let newFiltersOr = cloneDeep(this.state.context_filters_or);
        for (let i = 0; i < newFiltersOr.length; i++) {
            let orFiltersTmp = this.createFilterForApiSearchFromStateValues(newFiltersOr[i], filtersJoin);
            result_api_filters.push(orFiltersTmp);
        }

        //wenn keine Detailsuche und keine Kachelsuche eingegeben wurde
        //erstelle nur die FilterJoin für
        if (result_api_filters.length == 0 && filtersJoin && filtersJoin.length > 0) {
            for (let i = 0; i < filtersJoin.length; i++)
                result_api_filters.push(filtersJoin[i]);
        }

        console.log("result_api_filters", result_api_filters);

        ApiConnector.searchResultsWithOrFilter(result_api_filters, this.receiveSearchResultWithOr, this.receiveError);
    }

    createFilterForApiSearchFromStateValues(pFilters, pFiltersJoin) {
        let result_api_filters = [];

        let filter_id, opt_id, options;
        let active_options = [];

        //loop through state.filters and keep filters with active options
        for (filter_id in pFilters) {
            options = pFilters[filter_id].options;
            for (opt_id in options) {
                if (options[opt_id].active) {
                    active_options.push(opt_id);
                }
            }

            //are there any active options? If yes, keep filter & options
            if (active_options.length) {
                //separate between offer_types and list filters
                if (filter_id === Config.FILTER_OFFER_TYPES) {
                    //build+convert offer_type filters
                    result_api_filters.push(ApiConnector.buildOfferTypesFilter(active_options));
                } else {
                    //build+convert list filters and keep
                    result_api_filters.push(ApiConnector.buildListFilter(filter_id, active_options));
                }
            }

            //clear active_options for next iteration
            active_options = [];
        }

        //wenn min ein Filter angewendet werden muss fü
        if (result_api_filters.length > 0 && pFiltersJoin) {
            for (let i = 0; i < pFiltersJoin.length; i++)
                result_api_filters.push(pFiltersJoin[i]);
        }

        if (result_api_filters.length === 0)
            result_api_filters = null

        return result_api_filters;
    }

    //todo: move functionality of building filters for api call to ApiConnector
    getCurJoinFilters() {
        let basisFilter = [];
        //create textfilter
        if (this.m_mainTextfilter && this.m_mainTextfilter.length > 0) {
            let textfilter = {
                "Type": 'Text',
                "MerkmalTypeIDs": [],
                "Fields": [],
                "Values": [this.m_mainTextfilter],
                "OperatorType": ""
            };
            basisFilter.push(textfilter);
        }
        // create datefilter
        //check if default filterJoin_date is disabled
        if (!(Config.DISABLE_DEFAULT_DATEFILTER && DateFilter.isDefault(this.state.filterJoin_date))) {
            //add date filter
            if (this.state.filterJoin_date) {
                let df = this.state.filterJoin_date;
                let new_date_filter = ApiConnector.buildDateFilter(df.date_start, df.date_end);
                basisFilter.push(new_date_filter);
            }
        }
        // // create location filter
        let curJoinDistrict = this.state.filterJoin_district;
        if (curJoinDistrict !== null && curJoinDistrict !== window.undefined) {
            let districtOptionsActive = HelperFilter.getOptionsActiveFromSearchFilter(curJoinDistrict);
            if (districtOptionsActive.length > 0)
                basisFilter.push(ApiConnector.buildListFilter(curJoinDistrict.ID, districtOptionsActive.map(selectedOption => selectedOption.ID)));
        }

        return basisFilter;
    }

    getMainTextfilter() {
        return this.m_mainTextfilter;
    }

    getMainDatefilter() {
        if (!(Config.DISABLE_DEFAULT_DATEFILTER && DateFilter.isDefault(this.state.filterJoin_date))) {
            return this.state.filterJoin_date;
        }
        return null;
    }

    /**
     * @param      {bool}  pFilterJoinReset - default pFilterJoinReset = false
     */
    //todo: combine with resetAllFilters
    getFiltersReseted(pFilterJoinReset = false) {
        let new_state = {
            context_filters_or: []
        };

        let opts, filter_id, opt_id;

        //make copy of state.filters
        let new_filters = cloneDeep(this.state.filters);
        for (filter_id in new_filters) {
            opts = new_filters[filter_id].options;
            // falls der MainFilter nicht zurückgesetzt werden solll, übersrpinge das Zurücksetzen der Regionsuche
            //if(pFilterJoinReset || filter_id.toUpperCase()!== kommunen_id.toUpperCase())
            for (opt_id in opts) {
                opts[opt_id].active = false;
            }
        }
        new_state.filters = new_filters;

        //clear location filter
        new_state.location_filter = null;


        if (pFilterJoinReset) {
            let cloneFiltersJoin = cloneDeep(this.state.filterJoin_district);
            opts = cloneFiltersJoin.options;
            for (opt_id in opts) {
                opts[opt_id].active = false;
            }
            //reset date filter to default
            new_state.filterJoin_date = this.default_date_filter;
            new_state.filterJoin_district = cloneFiltersJoin;
            this.m_mainTextfilter = "";
        }
        //return new_state instead of setting >> this does not call func at the end
        return new_state;
    }


    callApiSearcResultshFromCardFiltersOr(pArraywithor_filter_and_options) {

        console.log('---- callApiSearcResultshFromCardFiltersOr');
        console.log(pArraywithor_filter_and_options);

        if (this.filtersLoaded()) {
            let options, filterTmp, optionsTmp;

            // vorher angewendete Filter leeren
            let new_state = this.getFiltersReseted(false);

            // hole die Filterstruktur um diese mit 'ersten' Filter von 'oder' zu fuellen
            let new_filters = new_state.filters;
            // hier werden alle weitere 'oder'-Filter gespeichert
            let new_filtersOr = [];

            //Flag für den allerersten Durchlauf
            let isStartFilter = true;
            let basisFilterOr = cloneDeep(new_filters);
            let filtersSetTmp = null;

            //Durchlaufe alle 'Oder'-Filter, die angewendet werden muessen
            for (let i = 0; i < pArraywithor_filter_and_options.length; i++) {
                let oderFilter = pArraywithor_filter_and_options[i];
                if (!isStartFilter) {
                    filtersSetTmp = cloneDeep(basisFilterOr);
                } else {
                    //zuerst startFilter zu fuellen, wird hier reference kopiert?
                    filtersSetTmp = new_filters;
                }
                //loop through incoming filters
                for (let filter_id in oderFilter) {
                    //get filter from incoming filters
                    options = oderFilter[filter_id];

                    //loop through incoming options
                    for (let option_id of options) {
                        filterTmp = filtersSetTmp[filter_id];
                        if (filterTmp) {
                            optionsTmp = filterTmp.options[option_id.toLowerCase()];
                            if (optionsTmp) {
                                //change filter option to active
                                optionsTmp.active = true;
                            }
                        }
                    }
                }
                if (!isStartFilter) {
                    new_filtersOr.push(filtersSetTmp);
                } else {
                    isStartFilter = false;
                }
            }

            console.log("callApiSearcResultshFromCardFiltersOr");
            console.log('new_filters', new_filters);
            console.log('new_filters_or', new_filtersOr);

            new_state.filters = new_filters;
            new_state.context_filters_or = new_filtersOr;
            new_state.search_results = null;
            new_state.redirect = <Redirect push to='/ergebnisse/'/>;
            this.setState(
                new_state,
                // search results, execute after state change
                () => {
                    this.prCallApiSearchForResults()
                }
            );
        }
    }


    /**
     * Resets all filters
     *
     * @param      {function}   func                    - is called after filters are resetted
     * @param      {boolean}  [dont_reset_date=false]   - when reset filters is called from quicksearch, the date has to stay the same
     * @param      {boolean}  [return_new_state=false]  - to prevent a rerender the state is returned and can then be modified further
     * @return     {object}   a state object
     */
    resetAllFilters(func, dont_reset_date = false, return_new_state = false) {
        let opts, filter_id, opt_id;

        //make copy of state.filters
        let new_filters = cloneDeep(this.state.filters);
        for (filter_id in new_filters) {
            opts = new_filters[filter_id].options;
            for (opt_id in opts) {
                opts[opt_id].active = false;
            }
        }

        let new_state = {
            filters: new_filters,
            context_filters_or: [],
            //clear location filter
            location_filter: null
        };

        //reset date filter to default
        if (!dont_reset_date)
            new_state['filterJoin_date'] = this.default_date_filter;

        //return new_state instead of setting >> this does not call func at the end
        if (return_new_state) {
            return new_state;
        } else {
            this.setState(new_state,
                //call function after filter reset
                func
            );
        }
    }


    /**
     * Gets the active filters as array with <span> wrappers.
     *
     * @return     {Array}  The active filters as jsx.
     */
    getActiveFiltersAsJSX() {

        let active_filters = [];

        //date filter
        let filterJoinDate = this.state.filterJoin_date;
        let def_date_filter = DateFilter.getDefault();
        let key_counter = 0;

        //Datum, check if filterJoinDate is not default date filter
        if (filterJoinDate && !(filterJoinDate.date_start == def_date_filter.date_start && filterJoinDate.date_end == def_date_filter.date_end)) {
            let date_start = filterJoinDate.date_start.split(" ")[0];
            let date_end = filterJoinDate.date_end.split(" ")[0];
            if (date_start === date_end)
                active_filters.push(<span key={key_counter++}>{date_start}</span>);
            else
                active_filters.push(<span key={key_counter++}>{date_start} bis {date_end}</span>);
        }

        //Freitextsuche
        if (this.m_mainTextfilter && this.m_mainTextfilter.length > 0) {
            active_filters.push(<span key={key_counter++}>"{this.m_mainTextfilter}"</span>);
        }


        //Regionale Suche
        if (this.state.filterJoin_district !== window.undefined && this.state.filterJoin_district !== null) {
            let filterJoinDistrictOptions = this.state.filterJoin_district.options;
            for (let optionKey in filterJoinDistrictOptions) {
                let option = filterJoinDistrictOptions[optionKey];
                if (option.active) {
                    active_filters.push(<span key={key_counter++}>{option.value}</span>);
                }
            }
        }


        //location filter
        let location_filter = this.state.location_filter;
        if (location_filter) {
            active_filters.push(<span
                key={key_counter++}>{location_filter.location + " / " + location_filter.radius + "km"}</span>)
        }

        //other filters, StartFilter
        let filters = this.state.filters;
        let tmpFilter, option;
        for (let filter_id in filters) {
            tmpFilter = filters[filter_id];
            for (let option_id in tmpFilter.options) {
                option = tmpFilter.options[option_id];
                if (option.active) {
                    active_filters.push(<span key={key_counter++}>{option.value}</span>);
                }
            }
        }

        //'OR' -Filter
        let filtersOr = this.state.context_filters_or;
        if (filtersOr) {
            let tmpFilterOr = null;
            //einzelne 'Or'-Filters durchlaufen
            for (let i = 0; i < filtersOr.length; i++) {
                //FilterStruktur mit Grundfiltern abrufen
                tmpFilterOr = filtersOr[i];
                for (let filter_id in tmpFilterOr) {
                    tmpFilter = tmpFilterOr[filter_id];
                    //Optionen bestimmten Filter auslesen
                    for (let option_id in tmpFilter.options) {
                        option = tmpFilter.options[option_id];
                        if (option.active) {
                            active_filters.push(<span key={key_counter++}>{option.value}</span>);
                        }
                    }
                }
            }
        }

        return active_filters;
    }


    /**
     * Checks if any filters are active
     *
     * @return     {Boolean}  true when there are active filters
     */
    checkFiltersActive() {

        let active_filters = this.getActiveFiltersAsJSX();

        return Boolean(active_filters.length);
    }


    /**
     * redirects to a detail page
     *
     * @param      {string}  dataset_id  - id is coming from the api search results
     */
    searchDetail(dataset_id) {

        //redirect to ViewDetail
        let route = '/detail/' + dataset_id;
        this.setState({
            redirect: <Redirect push to={route}/>
        });
    }

    searchDetailProvider(dataset_id) {
        //redirect to ViewDetail
        let route = '/detailorganisation/' + dataset_id;
        this.setState({
            redirect: <Redirect push to={route}/>
        });
    }


    /**
     * Gets the detail view from ApiConnector. When result are coming in, this.receiveSearchResultDetail is called
     *
     * @param      {string}  dataset_id - id is coming from the api search results
     */
    getDetailsFromApi(dataset_id) {
        this.showLoader(true);

        //search a dataset_id with the ApiConnector
        ApiConnector.searchDatasetID(dataset_id, this.receiveSearchResultDetail, this.receiveError);
    }


    /**
     * Gets the provider detail view from ApiConnector. When result are coming in, this.receiveSearchResultDetail is called
     *
     * @param      {string}  dataset_id - id is coming from the api search results
     */
    getProviderDetailsFromApi(dataset_id) {
        this.showLoader(true);

        //search a dataset_id with the ApiConnector
        ApiConnector.searchDatasetID(dataset_id, this.receiveProviderDetail, this.receiveError);
    }


    /**
     * callApiSearchResultsFromDetails is called from SearchFormEnhanced
     * Filters are extracted and applied and the API is searched
     *
     * @param      {string}  search_val - The search string
     * @param      {object}  form_state - All data collected from the form
     */
    //todo: split into two functions >> convert form data & getResults
    callApiSearchResultsFromDetails(form_state) {
        //reset filters without filtersJoin, don't save state yet, instead return new_state object
        let new_state = this.getFiltersReseted(false);

        //add filters from form
        let form_filter;
        for (let form_filter_name in form_state) {
            form_filter = form_state[form_filter_name];
            if (form_filter) {
                //get filter_id
                let filter_id;
                if (form_filter_name === Config.FILTER_OFFER_TYPES) {
                    filter_id = Config.FILTER_OFFER_TYPES;
                } else {
                    filter_id = Config.FILTER_IDS[form_filter_name];
                }

                if (filter_id) {
                    for (const filter_data of form_filter) {
                        if (filter_data)
                            new_state.filters[filter_id].options[filter_data.value].active = true;
                    }
                }
            }
        }

        //build location_filter
        //delete current location filter, if location is empty
        let location_filter;
        if (!form_state.location) {
            new_state['location_filter'] = null;
        } else {
            let radius = form_state.radius;
            //set default for search radius
            if (!radius) {
                radius = 10;
            }

            location_filter = new LocationFilter(form_state.location, radius);

            //store location_filter
            new_state['location_filter'] = location_filter;
        }

        new_state['search_results'] = null;
        new_state['redirect'] = <Redirect push to='/ergebnisse/'/>;
        this.setState(
            new_state,
            // search results, execute after state change
            () => {
                this.prCallApiSearchForResults()
            }
        );
    }

    callApiSearchResultsWithoutAllFilters() {
        //reset all filters & mainfilters, don't save state yet, instead return new_state object
        let new_state = this.getFiltersReseted(true);
        new_state['search_results'] = null;
        new_state['redirect'] = <Redirect push to='/ergebnisse/'/>;
        this.setState(
            new_state,
            // search results, execute after state change
            () => {
                this.prCallApiSearchForResults()
            }
        );
    }

    /**
     * Converts the filters in the state to the filter syntax that the API expects
     *
     * @return     {Array} Filter array for api search
     */
    convertFiltersForApiSearch() {
        let filter_id, opt_id, options;
        let api_filters = [];
        let active_options = [];

        //loop through state.filters and keep filters with active options
        for (filter_id in this.state.filters) {
            options = this.state.filters[filter_id].options;
            for (opt_id in options) {
                if (options[opt_id].active) {
                    active_options.push(opt_id);
                }
            }

            //are there any active options? If yes, keep filter & options
            if (active_options.length) {
                //separate between offer_types and list filters
                if (filter_id === Config.FILTER_OFFER_TYPES) {
                    //build+convert offer_type filters
                    api_filters.push(ApiConnector.buildOfferTypesFilter(active_options));
                } else {
                    //build+convert list filters and keep
                    api_filters.push(ApiConnector.buildListFilter(filter_id, active_options));
                }
            }

            //clear active_options for next iteration
            active_options = [];
        }

        //add location filter
        if (this.state.location_filter) {
            let new_location_filter = ApiConnector.buildLocationFilter(this.state.location_filter.location, this.state.location_filter.radius);
            api_filters.push(new_location_filter);
        }

        //check if default filterJoin_date is disabled
        if (!(Config.DISABLE_DEFAULT_DATEFILTER && DateFilter.isDefault(this.state.filterJoin_date))) {
            //add date filter
            if (this.state.filterJoin_date) {
                let df = this.state.filterJoin_date;
                let new_date_filter = ApiConnector.buildDateFilter(df.date_start, df.date_end);
                api_filters.push(new_date_filter);
            }
        }

        if (api_filters.length === 0)
            api_filters = null

        return api_filters;
    }

    receiveSearchResultWithOr(json_data) {
        let lastResults = cloneDeep(this.state.search_results);
        if (lastResults) {
            // diese Methode überschreibt die Arrays mit gleichen Schlüsseln 0,1,2 usw.
            //lastResults = Object.assign(lastResults, json_data);
            const concatResults = [...lastResults, ...json_data];
            let uniqueResults = concatResults.filter((arr, index, self) =>
                index === self.findIndex((t) => (t.DatasetId === arr.DatasetId && t.NextDate === arr.NextDate && t.NextTime === arr.NextTime)));
            uniqueResults.sort(function (a, b) {
                let aNextDate = a.NextDate;// "23.06.2020"
                let aNextTime = a.NextTime;// "10:00"
                let bNextDate = b.NextDate;// "23.06.2020"
                let bNextTime = b.NextTime;// "10:00"

                let result = DateFilter.dateCompare(aNextDate, aNextTime, bNextDate, bNextTime);
                return result >= 0;
            });
            json_data = uniqueResults;
        }

        this.showLoader(false);

        this.setState({
            search_results: json_data
        });
    }

    /**
     * When data from the API comes in the loader is hidden and the results saved in the state, which triggers a view update of the SearchDetails.
     * The function is usually called from the ApiConnector
     *
     * @param      {json}  json_data - API data
     */
    receiveSearchResultDetail(json_data) {
        this.showLoader(false);

        this.setState({
            searchdetail_results: json_data
        });
    }

    /**
     * When data from the API comes in the loader is hidden and the results saved in the state, which triggers a view update of the ProviderDetails.
     * The function is usually called from the ApiConnector
     *
     * @param      {json}  json_data - API data
     */
    receiveProviderDetail(json_data) {
        this.showLoader(false);

        this.setState({
            provider_results: json_data
        });
    }


    /**
     * When the API can not be searched an error is returned, that is logged in the console
     *
     * @param      {object}  error - The error
     */
    receiveError(error) {
        console.log('ViewPreview Error', error);
    }


    showLoader(active = true) {
        this.setState({
            loader_active: active
        });
    }

    setFontResize(size) {
        let max_font_resize = 3;

        if (0 <= size && size <= max_font_resize) {
            this.setState({
                font_resize: size
            });
        }
    }

    switchContrast() {
        this.setState({
            high_contrast: !this.state.high_contrast
        });
    }

    setHeaderImg(img) {
        if(img != this.state.header_img)
        {
            this.setState({
                header_img: img
            });
        }
    }

    /**
     * This is the main render function.
     * The App gives its state to the AppContext, so that all components have access to search & filter functions.
     * Also the Router for all urls is initialised.
     *
     * For specific site implementation @see: /sites/$sitename/App.js
     */
    render() {
        return (
            <div id="app-wrapper">
                {/*<BusyAnimationSharePartial loading={this.state.loader_active}></BusyAnimationSharePartial>*/}
                <Tracker active={Config.TRACKER.active}/>
                <AppContext.Provider value={this.state}>
                    {/*redirect_to_home */}
                    {this.state.redirect}
                    <Switch>
                        <RouteWrapper component={View404} cssClass={"wrapper-404"} layout={LayoutDefault}/>
                    </Switch>
                </AppContext.Provider>
            </div>
        );
    }
}
export default promisifySetState(AppBase);