import React, { Component } from 'react';

import { Link } from "react-router-dom";

import Config from '../../Config';
import Helper from '../../common/tools/Helper';
import {AppContext} from './AppContext';
import Mailto from 'react-protected-mailto';


class Footer extends Component {


	render() {
		let linkNutzungsbedingungen = '';
		let checkLink = null;
		try {
		 checkLink = require(`../../sites/${Config.PROJECT_FOLDER_NAME}/ViewNutzungsbedingungen.js`);
		} catch (err) {
			//wenn die Komponente nicht existiert wird eine Exception geschmiessen
		 	//return null;
		}
		if(checkLink){
			linkNutzungsbedingungen = Helper.StringToHtml('<a href="/nutzungsbedingungen/">Nutzungsbedingungen</a>&nbsp;.&nbsp;');
		}
		return(
			<footer>

				<div className="footer-row partner">
					<section className="kommunen">
						<div className="wrapper">
							<h3>{Config.FooterKundeText}</h3>
							<div className="img_wrapper"><img className="img-fluid" src={Config.ImgFooterKommunen} alt="Teilnehmende Kommunen"/></div>
						</div>
					</section>

					<section className="freistaat">
						<div className="wrapper">
							<h3>{Config.FooterTextStaat}</h3>
							<div className="img_wrapper"><img className="img-fluid" src={Config.ImgFooterStaat} alt="Freistaat Thüringen"/></div>
						</div>
					</section>
				</div>

				<div className="ruler"></div>

				<div className="cContainerFooterGebit">
				<section className="gebit">
					<h3>umgesetzt durch:</h3>
					<div className="img_wrapper"><img className="img-fluid" src={Config.ImgFooterGebit} alt="Gebit Münster"/></div>
					<p>{Config.FooterUmsetzungText}</p>
				</section>
				</div>

				<section className="col-12 section-legalcopy">
					<h3 className="title">Rechtliches und Copyrightinformationen</h3>
					<div className="desc">
						<p>copyright &copy; 2020&nbsp;.&nbsp;{linkNutzungsbedingungen}<Link to="/impressum/">Impressum</Link>&nbsp;.&nbsp;<Link to="/datenschutz/">Datenschutzerklärung</Link></p>
					</div>
				</section>

			</footer>
		);
	}
}
Footer.contextType = AppContext;

export default Footer;
