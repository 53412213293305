/**
 * Internal representation of the DateFilter
 *
 * @class      DateFilter (name)
 */
export default class DateFilter{

	// static TODAY = "today";
	// static TOMORROW = "tomorrow";
	// static WEEKEND = "weekend";

	constructor(date_start, date_end) {

		this.date_start = date_start;
		if(date_end)
			this.date_end = date_end;
		else
			this.date_end = date_start;
	}

	static formatDate(date){
		let date_format = require('dateformat');

		return date_format(date, "dd.mm.yyyy HH:MM");
	}

	static getFilterFromDates(start_date, end_date){
		let start = DateFilter.formatDate(start_date);
		let end = DateFilter.formatDate(end_date);
		return new DateFilter(start, end);
	}

	static getToday(){
		let today = new Date();
		today.setHours(0,0);
		let start = DateFilter.formatDate(today);

		//set time to 23:59
		today.setHours(23, 59);
		let end = DateFilter.formatDate(today);
		return new DateFilter(start, end);
	}

	static getTomorrow(){
		let tomorrow = new Date();
		tomorrow.setDate(new Date().getDate()+1);

		//begin search at 00:00
		tomorrow.setHours(0,0);
		let start = DateFilter.formatDate(tomorrow);

		//end search at 23:59
		tomorrow.setHours(23,59);
		let end = DateFilter.formatDate(tomorrow)

		return new DateFilter(start, end);
	}

	static getWeekend(){

		let today = new Date();

		let start, end;
		
		//if curret day is a sunday, show only sunday's events
		if(today.getDay() === 0)
		{
			start = DateFilter.getNextDayOfWeek(today, 0);
			end = DateFilter.getNextDayOfWeek(today, 0);
		}
		//next saturday + sunday
		else
		{
			start = DateFilter.getNextDayOfWeek(today, 6);
			end = DateFilter.getNextDayOfWeek(today, 0);

			//if current day is not saturday, set start time to 00:00
			if(today.getDay() !== 6)
				start.setHours(0, 0);
		}

		//format dates properly
		start = DateFilter.formatDate(start);

		//set end time to end of day
		end.setHours(23, 59);
		end = DateFilter.formatDate(end);

		return new DateFilter(start, end);
	}

	static includeNextDay(df)
	{
		let new_end_date = DateFilter.parseDate(df.date_end);
		new_end_date.setDate(new_end_date.getDate() + 1);
	
		let new_end = DateFilter.formatDate(new_end_date);
		
		return new DateFilter(df.date_start, new_end);
	}

	static parseDate(date_str)
	{
		let parts = date_str.split(' ');
		let date = parts[0];
		let time = parts[1];

		let date_parts = date.split('.');
		let day = date_parts[0];
		let month = date_parts[1];
		let year = date_parts[2];

		let new_date = new Date()
		new_date.setFullYear(year);
		new_date.setMonth(month-1);
		new_date.setDate(day);

		if(time !== window.undefined){
			let time_parts = time.split(':');
			let hours = time_parts[0];
			let minutes = time_parts[1];

			new_date.setHours(hours);
			new_date.setMinutes(minutes);
		}
		return new_date;
	}

	/**
	 * Default DateFilter is 2 years from today 00:00
	 *
	 * @return     {DateFilter}  default DateFilter
	 */
	static getDefault(){
		let today = new Date();
		today.setHours(0,0);
		let start = DateFilter.formatDate(today);

		//end is two years later
		today.setFullYear(today.getFullYear() + 2);
		let end = DateFilter.formatDate(today);

		return new DateFilter(start, end);
	}
	
	/**
	 * Check if filter is default_date_filter
	 *
	 * @param 		{DateFilter} filterJoin_date - filter to check
	 * @return     {Boolean}  true if filterJoin_date == default_date_filter
	 */
	static isDefault(filterJoin_date){
		let def_date_filter = DateFilter.getDefault();
		return (filterJoin_date.date_start === def_date_filter.date_start && filterJoin_date.date_end === def_date_filter.date_end); 
	}

	//https://codereview.stackexchange.com/questions/33527/find-next-occurring-friday-or-any-dayofweek
	//day 0-6: monday-sunday, starting from today
	static getNextDayOfWeek(date, dayOfWeek) {
	    var resultDate = new Date(date.getTime());
	    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
	    return resultDate;
	}

	//1 => firstdate earlier
	//0 => equals
	//-1 => firstdate later
	//-2 => eins von beiden kein Datum und konnte nicht verglichen werden
	static dateCompare(pFirstDate, pFirstTime,  pSecondDate, pSecondTime){
		if(pFirstDate && pSecondDate){
			let aPartsDate = pFirstDate.split(".");
			let bPartsDate = pSecondDate.split(".");

			let aPartsTime = "00:00".split(":");
			if(pFirstTime){
				aPartsTime = pFirstTime.split(":");
			}
			let bPartsTime = "00:00".split(":");
			if(pSecondTime){
				bPartsTime = pSecondTime.split(":");
			}
			let aDate = new Date(parseInt(aPartsDate[2], 10),
													 parseInt(aPartsDate[1], 10) - 1, // Careful, month starts at 0!
													 parseInt(aPartsDate[0], 10),
													 parseInt(aPartsTime[0], 10),
													 parseInt(aPartsTime[1], 10)
													);

			let bDate = new Date(parseInt(bPartsDate[2], 10),
														parseInt(bPartsDate[1], 10) - 1, // Careful, month starts at 0!
														parseInt(bPartsDate[0], 10),
														parseInt(bPartsTime[0], 10),
														parseInt(bPartsTime[1], 10)
													);
			if(aDate < bDate)	return 1;
			if(aDate === bDate)	return 0;
			return -1;
		}
		return -2;
	}
}
