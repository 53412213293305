import React, {Component} from 'react';
import {Link, Redirect} from "react-router-dom";
import {scroller} from 'react-scroll';
import Dummy from 'dummyjs';

import ConfigCards from '../../ConfigCards';

import {AppContext} from '../../common/app/AppContext';
import CreateSearchCardsWrapper from '../../common/components/CreateSearchCardsWrapper';

import MessageSharePartial from '../../common/components/MessageSharePartial';


/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewThema (name)
 */
class ViewThema extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount(prevProps, prevState) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    render() {
        let out = null;
        //this.props.match is coming from the Router
        let topic = this.props.match.params.topic;
        let page_data = ConfigCards.CARDS_LEVEL_ONE[topic];
        let title = "";
        let description = "";
        this.context.setHeaderImg(page_data.cardheaderimg);

        //get page data
        if (page_data) {

            title = page_data.cardheadertitle;
            description = page_data.cardheaderbody;

            let siteContent = '';
            let search_cards = page_data.cardhandel_searchcards;

            if (page_data.message)
            {
                siteContent = <MessageSharePartial> {page_data.message} </MessageSharePartial>;
            }
            else if (search_cards && Object.keys(search_cards).length > 0)
            {
                siteContent = (
                    <CreateSearchCardsWrapper
                        p_wrapperconfigcards={search_cards}
                        title={title}
                        description={description}
                    />
                );

            }

            //create page
            out = (
                <div className="view-thema" name="scroll_start">
                    {siteContent}
                </div>
            );
        } else {
            //redirect to 404
            out = <Redirect to="/404"/>;
        }

        return out;
    }
}

ViewThema.contextType = AppContext;
export default ViewThema;
