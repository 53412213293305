import React from 'react';

import {AppContext} from "../../common/app/AppContext";
import Config from '../../Config';


/**
 * This class describes a filter drop down.
 *
 * @class      FilterDropDownImmediately (name)
 * @prop 		{string} p_label
 * //@prop 		{string} p_FilterId - id from Config.FILTER_IDS
 * @props		{SearchFilter} p_SearchFilter
 */
export default class FilterDropDownImmediately extends React.Component {

	constructor(props) {
		super(props);

		this.active = false;
	}

	convertApiItemsToSearchButtons(pFilter){

    let options = [];
		if(pFilter)
		{
			let num = 0;
			this.active = false;
				for(let option_id in pFilter.options)
				{
						let option = pFilter.options[option_id];
						//create search call and class_name
						let click_call;
						let class_name = "dropdown-item" + (option.active ? " active" : "");

						if(pFilter.ID === Config.FILTER_IDS['district']){
							click_call = (e) => {this.context.toggleFilterDistrictAndSearch(pFilter.ID, option_id)};
						}
						else if(this.props.is_search_card){
							click_call = (e) => {
								this.context.resetAllFilters(
										() => {
											this.context.toggleFilterDistrictAndSearch(pFilter.ID, option_id)
										}
									);
							};
						}
						else{
							console.error("FilterDropDownImmediately for filters other than district needs to be implemented!");
							// click_call = (e) => {this.context.toggleFilterAndSearch(pFilter.ID, option_id)};
						}

			      options.push(
			       	<button key={num++} className={class_name}
			       			//set filter via context and update search(true);
			       			onClick={click_call}
			      	>{option.value}</button>
			      );

			      //is one of the options active? Then the whole component is active
			      if(option.active)
			       	this.active = true;
				}

		}
    return options;
    }

	render() {
		let filter;

		if(this.props.p_SearchFilter){
			//get filter from context via ID & get ID from Config
			filter = this.props.p_SearchFilter; // this.context.filters[this.props.p_FilterId];
		}

		let filterOptions = this.convertApiItemsToSearchButtons(filter);


		let class_name = "nav-link dropdown-toggle" + (this.active ? " active" : "");

		return (
			<div className="filterdropdown">
				<button className={class_name}
								data-toggle="dropdown"
								role="button"
								aria-haspopup="true"
								aria-expanded="false"
				>
					{this.props.p_label}
				</button>
				<div className="dropdown-menu">
					{filterOptions}
				</div>
			</div>
		);
	}
}
FilterDropDownImmediately.contextType = AppContext;
