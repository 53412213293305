import React from 'react';
import Config from './Config';

//Bilder fürs HomeCards
import imgHome1 from './sites/stuttgart/media/kacheln/schwangerschaft_und_geburt.jpg';
import imgHome2 from './sites/stuttgart/media/kacheln/begegnung_treff.jpg';
import imgHome3 from './sites/stuttgart/media/kacheln/entlastung.jpg';
import imgHome4 from './sites/stuttgart/media/kacheln/gesundheit.jpg';
import imgHome5 from './sites/stuttgart/media/kacheln/kinderbetreuung.jpg';
import imgHome6 from './sites/stuttgart/media/kacheln/geld.jpg';
import imgHome7 from './sites/stuttgart/media/kacheln/beratung_hilfe.jpg';
import imgHome8 from './sites/stuttgart/media/kacheln/karte.jpg';

import imgL1_1 from './sites/stuttgart/media/kacheln/schwangerschaftsvorsorge.jpg';
import imgL1_2 from './sites/stuttgart/media/kacheln/schwangerschaftkurse.jpg';
import imgL1_3 from './sites/stuttgart/media/kacheln/schwangerschaftsberatung.jpg';
import imgL1_4 from './sites/stuttgart/media/kacheln/rund_um_geburt.jpg';
import imgL1_5 from './sites/stuttgart/media/kacheln/nach_geburt.jpg';
import imgL1_6 from './sites/stuttgart/media/kacheln/guter_start.jpg';
import imgL1_7 from './sites/stuttgart/media/kacheln/depression.jpg';
import imgL1_8 from './sites/stuttgart/media/kacheln/willkommensbesuche.jpg';

import IImgFfoSpielplatz from './sites/thueringen/media/themen/ffo_spielplatz.jpg';
import IImgFfoToilette from './sites/thueringen/media/themen/ffo_toilette.jpg';
import IImgFfoStillpunkt from './sites/thueringen/media/themen/ffo_stillpunkt.jpg';
import IImgFfoWickelpunkt from './sites/thueringen/media/themen/ffo_wickelpunkt.jpg';
import IImgFfoGuteFee from './sites/thueringen/media/themen/ffo_gutefee.jpg';
import IImgFfoElterncafe from './sites/thueringen/media/themen/ffo_elterncafe.jpg';

import imgTemp from './sites/stuttgart/media/kacheln/platzhalter.png';
import imgHeaderCards from './sites/stuttgart/media/header/header_cards.jpg';
import imgHeaderSchwangerschaft from './sites/stuttgart/media/header/header_schwangerschaft_und_geburt.jpg';
import imgHeaderBegegnung from './sites/stuttgart/media/header/header_begegnung_treff.jpg';


export default class ConfigCards {

    //man braucht eine statische Variablen, damit diese von anderen js-Dateien geladen werden können
    //sonst für die lokale verwendung, braucht man diese nicht
    static ImgFfoSpielplatz = IImgFfoSpielplatz;
    static ImgFfoToilette = IImgFfoToilette;
    static ImgFfoStillpunkt = IImgFfoStillpunkt;
    static ImgFfoWickelpunkt = IImgFfoWickelpunkt;
    static ImgFfoGuteFee = IImgFfoGuteFee;
    static ImgFfoElterncafe = IImgFfoElterncafe;

    static cardTitleBeratung = 'Beratung';
    static cardTitleFreizeit = 'Freizeit & Kultur';
    static cardTitleGesundheit = 'Gesundheit';
    static cardTitleBildung = 'Bildung';
    static cardTitleKinder = 'Kinder und Jugend';
    static cardTitleLebenImAlter = 'Leben im Alter';
    static cardTitleFfoHeader = 'Familien- freundliche Orte';
    static cardTitleFfoKachel = 'Familienfreundliche Orte';
    static cardTitleRegionaleSuche = 'Regionale Suche';

    static cardTitles = {
        'schwangerschaft': "Schwangerschaft & Geburt",
        'begegnung': "Begegnung, Treff und Kurs",
        'unterstuetzung': "Entlastung und Unterstützung",
        'gesundheit': "Gesundheit",
        'kinderbetreuung': "Kinderbetreuung",
        'geld': "Rund ums Geld",
        'hilfe': "Beratung und Hilfe",
        'wickeln': "Wickeln und Stillen"
    }

    static CARDS_HOME = {
        'cardid_1': {
            cardtitle: this.cardTitles.schwangerschaft,
            cardimg: imgHome1,
            cardhandel_link_to: '/thema/schwangerschaft',
            cardbody: 'Kaum etwas verändert das Leben so sehr wie Ihre Schwangerschaft. Von Beratungsstellen bis Hebammen, finden Sie Informationen für sich und Ihre Familie.'
        },
        'cardid_2': {
            cardtitle: this.cardTitles.begegnung,
            cardimg: imgHome2,
            cardhandel_link_to: '/thema/begegnung',
            cardbody: 'Gemeinsam lassen sich die Familienerlebnisse noch besser teilen. Finden Sie Gruppen und Treffs mit anderen Familien.'
        },
        'cardid_3': {
            cardtitle: this.cardTitles.unterstuetzung,
            cardimg: imgHome3,
            cardhandel_link_to: '/thema/unterstuetzung',
            cardbody: 'Die neue Situation bringt viele, manchmal zu viele Herausforderungen, mit sich. Lassen Sie sich in dieser besonderen Lebenslage unterstützen.'
        },
        'cardid_4': {
            cardtitle: this.cardTitles.gesundheit,
            cardimg: imgHome4,
            cardhandel_link_to: '/thema/gesundheit',
            cardbody: 'Hilfen und Unterstützung für ein gesundes Aufwachsen Ihres Kindes. Hier finden Sie Orientierung, bei welchen Fragen Sie sich wohin wenden können.'
        },
        'cardid_5': {
            cardtitle: this.cardTitles.kinderbetreuung,
            cardimg: imgHome5,
            cardhandel_link_to: '/thema/kinderbetreuung',
            cardbody: 'Ob tagsüber, abends oder in den Ferien - in Stuttgart haben Sie eine Vielfalt an möglichen Betreuungsformen.'
        },
        'cardid_6': {
            cardtitle: this.cardTitles.geld,
            cardimg: imgHome6,
            cardhandel_link_to: '/thema/geld',
            cardbody: 'Lernen Sie die wichtigsten Hilfen und Leistungen kennen, um in Ihrer Familiensituation besser planen zu können.'
        },
        'cardid_7': {
            cardtitle: this.cardTitles.hilfe,
            cardimg: imgHome7,
            cardhandel_link_to: '/thema/hilfe',
            cardbody: 'Die veränderte Lebenssituation bringt viel Neues mit sich. Lassen Sie sich beraten und gemeinsam Antworten auf Ihre Fragen rund um die Familie finden.'
        },
        'cardid_8': {
            cardtitle: this.cardTitles.wickeln,
            cardimg: imgHome8,
            message: 'Wickeln und Stillen',
            cardbody: ''
        }
    };

    /*
    * 'link_to': für die Route
    * @cardheadertitle: Headertitle
    * @cardheaderbody: Headertext
    * @cardheaderimg: headerImg
    * Title der Unterkachel
    * @cardimg: Bild der Unterkachel
    * @cardbody: Text der Unterkachel
    *
    * Optionen für Aktionen des Buttons 'mehr anzeigen' (darf ein von folgenden angewendet werden):
    * @cardhandel_searchcards: Unterthemen
    * @cardhandel_filters: einen bestimmten Filter anwenden
    * @message: eine Mitteilung ausgeben, z.B. falls die Unterseite in der Entwicklung sich befindet
    * @cardhandel_filterdropdown: wenn ein DropDownBox mit einem Filter aus dem AppConntext geladen werden soll
    **/

// (8) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]0: {
// Id: "98873736-6137-4ee5-badf-10c8bcdb3ed6", Designation: "Begegnung, Treff und Kurs", Checked: false}1: {
// Id: "0a2e536d-1b28-492b-82ec-010fedb2aaed", Designation: "Beratung und Hilfe", Checked: false}2: {
// Id: "e90e2eca-29e5-4ac9-962a-0b4ee6b23381", Designation: "Entlastung und Unterstützung", Checked: false}3: {
// Id: "c5a52a5b-40cf-4079-8496-ba10f5a61f6c", Designation: "Gesundheit", Checked: false}4: {
// Id: "e8cedfbe-a13e-4c5f-aee8-1e372ae35cff", Designation: "Kinderbetreuung", Checked: false}5: {
// Id: "4e2ddbb9-6118-45de-a868-82803d49e54f", Designation: "Redaktionelle Inhalte", Checked: false}6: {
// Id: "acd41520-05e6-49bd-a38d-4c72825caa99", Designation: "Rund ums Geld", Checked: false}7: {
// Id: "a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816", Designation: "Schwangerschaft und Geburt", Checked: false}length: 8__proto__: Array(0)


static CARDS_LEVEL_ONE = {

        'schwangerschaft': {
            cardheadertitle: this.cardTitles.schwangerschaft,
            cardheaderbody: 'Die Schwangerschaft und Geburt sind eine aufregende Zeit. Viele Fragen stellen sich, aber es gibt auch viele Angebote um sich auf die Zeit nach der Geburt vorzubereiten.',
            cardheaderimg: imgHeaderSchwangerschaft,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: <span>Schwangerschafts&shy;vorsorge</span>,
                    cardimg: imgL1_1,
                    cardbody: '',
                    cardhandel_link_to: '/schwangerschaftsvorsorge'
                },
                "2": {
                    cardtitle: 'Kurse in der Schwangerschaft',
                    cardimg: imgL1_2,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                },
                "3": {
                    cardtitle: <span>Schwangerschafts- und Schwangerschafts&shy;konfliktberatung</span>,
                    cardimg: imgL1_3,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                },
                "4": {
                    cardtitle: 'Rund um die Geburt',
                    cardimg: imgL1_4,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                },
                "5": {
                    cardtitle: 'Nach der Geburt',
                    cardimg: imgL1_5,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                },
                "6": {
                    cardtitle: 'Guter Start für Familien - von der Geburtsklinik in den Familienalltag',
                    cardimg: imgL1_6,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                },
                "7": {
                    cardtitle: 'Postpartale/postnatale Depression',
                    cardimg: imgL1_7,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                },
                "8": {
                    cardtitle: 'Willkommensbesuche',
                    cardimg: imgL1_8,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['a1f4833a-c8ed-47bc-b2e3-ce4d5fd37816']}
                    ]
                }
            }
        },

        'begegnung': {
            cardheadertitle: this.cardTitles.begegnung,
            cardheaderbody: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies.',
            cardheaderimg: imgHeaderBegegnung,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: 'Willkommensfrühstück',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "2": {
                    cardtitle: 'Muttersprachliche Gruppenangebote',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "3": {
                    cardtitle: 'Offene Elterntreffs',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "4": {
                    cardtitle: '(Kleinkind-)Spielplätze',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "5": {
                    cardtitle: 'Winterspielplätze/Indoor-Spielplätze',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "6": {
                    cardtitle: 'Abenteuerspielplätze und Jugendfarmen',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "7": {
                    cardtitle: 'Waldheim und Naturfreundehäuser',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "8": {
                    cardtitle: 'Stadtteil- und Familienzentren',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "9": {
                    cardtitle: 'Eltern-Kind-Kurse',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "10": {
                    cardtitle: 'Sprachkurse',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                },
                "11": {
                    cardtitle: 'weitere Kurse/Angebote',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['98873736-6137-4ee5-badf-10c8bcdb3ed6']}
                    ]
                }
            }
        },

        'unterstuetzung': {
            cardheadertitle: this.cardTitles.unterstuetzung,
            cardheaderbody: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies.',
            cardheaderimg: imgHeaderCards,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: 'Familienunterstützung',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e90e2eca-29e5-4ac9-962a-0b4ee6b23381']}
                    ]
                },
                "2": {
                    cardtitle: 'Entlastung durch Ehrenamtliche',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e90e2eca-29e5-4ac9-962a-0b4ee6b23381']}
                    ]
                },
                "3": {
                    cardtitle: 'Elternkurse/Gruppenangebote',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e90e2eca-29e5-4ac9-962a-0b4ee6b23381']}
                    ]
                },
                "4": {
                    cardtitle: 'Beratungsangebote',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e90e2eca-29e5-4ac9-962a-0b4ee6b23381']}
                    ]
                },
                "5": {
                    cardtitle: 'Selbsthilfegruppen',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e90e2eca-29e5-4ac9-962a-0b4ee6b23381']}
                    ]
                }
            }
        },

        'gesundheit': {
            cardheadertitle: this.cardTitles.gesundheit,
            cardheaderbody: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies.',
            cardheaderimg: imgHeaderCards,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: 'Kinderärztinnen / Kinderärzte',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                },
                "2": {
                    cardtitle: 'Elternkurse',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                },
                "3": {
                    cardtitle: 'Kinderklinik Olgahospital „Olgäle"',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                },
                "4": {
                    cardtitle: 'Angebote des Gesundheitsamts',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                },
                "5": {
                    cardtitle: 'Leistungen der Krankenkasse',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                },
                "6": {
                    cardtitle: 'Beratung für Eltern mit Babys und Kleinkindern',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                },
                "7": {
                    cardtitle: 'Weitere Angebote',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['c5a52a5b-40cf-4079-8496-ba10f5a61f6c']}
                    ]
                }
            }
        },

        'kinderbetreuung': {
            cardheadertitle: this.cardTitles.kinderbetreuung,
            cardheaderbody: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies.',
            cardheaderimg: imgHeaderCards,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: 'Offene Angebote',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "2": {
                    cardtitle: 'Babysittervermittlung',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "3": {
                    cardtitle: 'Leihgroßeltern',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "4": {
                    cardtitle: 'Au-Pair',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "5": {
                    cardtitle: 'Kindertageseinrichtung (Kita)',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "6": {
                    cardtitle: 'Tagespflege',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "7": {
                    cardtitle: 'Notfallbetreuung',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                },
                "8": {
                    cardtitle: 'Angebote in den Ferien',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['e8cedfbe-a13e-4c5f-aee8-1e372ae35cff']}
                    ]
                }
            }
        },

        'geld': {
            cardheadertitle: this.cardTitles.geld,
            cardheaderbody: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies.',
            cardheaderimg: imgHeaderCards,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: 'Leistungen in der Schwangerschaft',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                },
                "2": {
                    cardtitle: 'Leistungen für Familien und Kinder - Elterngeld & Co.',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                },
                "3": {
                    cardtitle: 'Stuttgarter Leistungen für Familien',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                },
                "4": {
                    cardtitle: 'Unterhaltsleistungen',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                },
                "5": {
                    cardtitle: 'Leistungen bei Arbeitslosigkeit',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                },
                "6": {
                    cardtitle: 'Schulden',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                },
                "7": {
                    cardtitle: 'Günstig einkaufen & sparen',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['acd41520-05e6-49bd-a38d-4c72825caa99']}
                    ]
                }
            }
        },

        'hilfe': {
            cardheadertitle: this.cardTitles.hilfe,
            cardheaderbody: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies.',
            cardheaderimg: imgHeaderCards,
            cardhandel_searchcards: {
                "1": {
                    cardtitle: 'Schwangerschaftsberatung',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "2": {
                    cardtitle: 'Beratungsangebote des Jugendamtes Stuttgart',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "3": {
                    cardtitle: 'Erziehungs- und Lebensberatung der Träger der freien Wohlfahrtspflege',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "4": {
                    cardtitle: 'Beratungen zum Kinderschutz',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "5": {
                    cardtitle: 'Beratungsstellen für Kinder mit Entwicklungsverzögerungen und Behinderungen',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "6": {
                    cardtitle: 'Beratung für Frauen',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "7": {
                    cardtitle: 'Beratung für Männer',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "8": {
                    cardtitle: 'Beratungen für Migrantinnen / Migranten',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "9": {
                    cardtitle: 'Krisen- und Notfalldienst',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "10": {
                    cardtitle: 'Rechtsberatung',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                },
                "11": {
                    cardtitle: 'Wohnen und Wohnprojekte',
                    cardimg: imgTemp,
                    cardbody: '',
                    cardhandel_filters: [
                        {[Config.FILTER_OFFER_TYPES]: ['0a2e536d-1b28-492b-82ec-010fedb2aaed']}
                    ]
                }
            }
        }
    };

    static MENU_LINKS_ROUTE = [
        // { //Startseite ist immer dabei
        //   menu_title: 'Startseite',
        //   menu_callback: '?'
        // },
        {
            menu_title: 'Themenseiten',
            menu_untermenus: [
                {
                    menu_title: this.CARDS_HOME['cardid_1'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_1'].cardhandel_link_to
                },
                {
                    menu_title: this.CARDS_HOME['cardid_2'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_2'].cardhandel_link_to
                },
                {
                    menu_title: this.CARDS_HOME['cardid_3'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_3'].cardhandel_link_to
                },
                {
                    menu_title: this.CARDS_HOME['cardid_4'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_4'].cardhandel_link_to
                },
                {
                    menu_title: this.CARDS_HOME['cardid_5'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_5'].cardhandel_link_to
                },
                {
                    menu_title: this.CARDS_HOME['cardid_6'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_6'].cardhandel_link_to
                },
                {
                    menu_title: this.CARDS_HOME['cardid_7'].cardtitle,
                    menu_link_to: this.CARDS_HOME['cardid_7'].cardhandel_link_to
                }
            ]
        },
        // {
        //     menu_title: 'Kontakt',
        //     menu_link_to: '/kontakt'
        // },
        {
            menu_title: 'News',
            menu_link_to: '/news'
        }
    ];

}
