import React, {Component} from 'react';
import {Link, NavLink, Redirect} from "react-router-dom";

import {AppContext} from '../app/AppContext';

import FilterDropDownImmediately from './FilterDropDownImmediately';


class CreateSearchCard extends Component {
    static defaultProps = {
        p_cardlinktext: "mehr anzeigen"
    }

    constructor(props) {
        super(props);

        this.searchFromCard = this.searchFromCard.bind(this);
    }


    searchFromCard(pCardFilters) {
        this.context.callApiSearcResultshFromCardFiltersOr(pCardFilters);
    }


    render() {
        let cardInfos = this.props.p_configCard;
        this.class_name = "search-card";
        let p_classextension = (this.props.p_classextension) ? this.props.p_classextension : '';

        let card_link = <span className="card-link">{this.props.p_cardlinktext}</span>;
        if (this.props.p_cardlinktext === null || this.props.p_cardlinktext === window.undefined || this.props.p_cardlinktext.length === 0) {
            card_link = "";
        }

        if (cardInfos.cardhandel_filterdropdown && cardInfos.cardhandel_filterdropdown['card_filter_id'].length > 0) {
            let filter_region = this.context.filters[cardInfos.cardhandel_filterdropdown['card_filter_id']];
            // hier kann filter_region noch undefined sein, da auf der Startseite müssen noch filter geladen werden
            // "Wähle eine Region"
            card_link = (<FilterDropDownImmediately
                p_label={cardInfos.cardhandel_filterdropdown['card_filter_label']}
                p_SearchFilter={this.context.filterJoin_district}
                is_search_card={true}
            />);
            this.class_name = this.class_name.replace('no-link', '');
        }
        this.card = (
            <div className="card">

                <div className="card-body intro">
                    <img className="img-fluid" src={cardInfos.cardimg} alt={cardInfos.cardtitle}/>
                    <h2 className="card-title">{cardInfos.cardtitle}</h2>
                </div>

                <div className="card-body description">
                    <article className="card-text">{cardInfos.cardbody}</article>
                </div>

                <div className="card-body more-bar">
                    {card_link}
                </div>
            </div>
        );

        let card_out = null;
        let cssClasses = `${this.class_name} ${p_classextension}`;
        if (cardInfos.message && cardInfos.message.length > 0) {
            card_out = (
                <section className={cssClasses} onClick={() => {
                    alert(cardInfos.message);
                }}>
                    {this.card}
                </section>
            );
        } else if (cardInfos.cardhandel_filters && cardInfos.cardhandel_filters.toString().length > 0) {
            card_out = (
                <section className={cssClasses} onClick={this.searchFromCard.bind(this, cardInfos.cardhandel_filters)}>
                    {/*p_classextension = .column-3, bedeutet 3 Spaltigkeit anwenden statt 4 wie auf der Startseite*/}
                    {this.card}
                </section>
            );
        } else if (cardInfos.cardhandel_link_to && cardInfos.cardhandel_link_to.toString().length > 0) {
            card_out = (
                <section className={cssClasses}>
                    <Link to={cardInfos.cardhandel_link_to}>
                        {this.card}
                    </Link>
                </section>
            );
        } else if (this.props.p_callback && this.props.p_callbackparam) {
            this.callBack = (e) => this.props.p_callback(this.props.p_callbackparam);
            card_out = (<section className={cssClasses} onClick={this.callBack}>
                {/*p_classextension = .column-3, bedeutet 3 Spaltigkeit anwenden statt 4 wie auf der Startseite*/}
                {this.card}
            </section>);
        } else if (cardInfos.cardhandel_redirect_to && cardInfos.cardhandel_redirect_to.toString().length > 0) {
            card_out = (
                <section className={cssClasses}>
                    <NavLink to={cardInfos.cardhandel_redirect_to}>
                        {this.card}
                    </NavLink>
                </section>
            );
        } else if (cardInfos.cardhandel_link_to_external && cardInfos.cardhandel_link_to_external.toString().length > 0) {
            card_out = (
                <section className={cssClasses}>
                    <a href={cardInfos.cardhandel_link_to_external} target="_blank">
                        {this.card}
                    </a>
                </section>
            );
        } else {
            this.class_name = `${this.class_name}  no-link`;
            card_out = (
                <section className={cssClasses}>
                    {this.card}
                </section>
            );
        }

        return card_out;
    }
}

CreateSearchCard.contextType = AppContext;

export default CreateSearchCard;
