import React from 'react';
import Config from '../../Config';
import Svg from "./Svg.js";

//https://blog.lftechnology.com/using-svg-icons-components-in-react-44fbe8e5f91
//https://codesandbox.io/s/ryy418r5km?from-embed=&file=/src/SVGIcon.js:4619-4642
//https://css-tricks.com/scale-svg/


const getViewBox = pConfigKey => {
  switch(pConfigKey){
    case Config.IconSvgParkplatz:
      return '0 0 42 70';
    case Config.IconSvgWc:
      return '0 0 112 69';
    case Config.IconSvgTaub:
      return '0 0 105 130';
    case Config.IconSvgBlind:
      return '0 0 94 130';
    case Config.IconSvgAufzug:
      return '0 0 84 130';
    case Config.IconSvgRollstuhl:
      return '0 0 84 130';
    case Config.IconSvgKinderwagen:
      return '0 0 512 512';
    case Config.IconResultSetSvgOrt:
      return '0 0 77 53';
    case Config.IconResultSetSvgOrt_Filled:
      return '0 0 77 53';
    case Config.IconResultSetNeu:
      return '0 0 512 512';
    default:
      return "0 0 32 32";
  }
};
const getPath = (pConfigKey, props) => {
  switch(pConfigKey){
    case Config.IconSvgParkplatz:
      return (
        <path
          {...props}
          d="M 4.00,13.00
           C 11.65,13.00 27.75,11.91 33.90,16.01
             41.08,20.79 40.52,32.47 32.98,37.15
             28.33,40.03 18.46,40.00 13.00,40.00
             13.00,42.74 13.34,52.77 12.40,54.69
             11.11,57.31 6.79,57.47 4.99,55.26
             3.83,53.83 4.01,50.78 4.00,49.00
             4.00,49.00 4.00,13.00 4.00,13.00 Z
           M 13.00,20.10
           C 13.00,22.27 12.74,28.94 13.60,30.69
             14.67,32.87 16.86,32.91 19.00,32.98
             21.85,33.06 25.39,32.89 27.40,30.57
             29.91,27.68 29.39,21.73 23.98,20.10
             22.70,19.98 20.37,20.01 19.00,20.10
             19.00,20.10 13.00,20.10 13.00,20.10 Z"
        />
      );
    case Config.IconSvgWc:
      return (
        <path
          {...props}
          d="M 5.00,13.00
           C 5.00,13.00 13.49,14.02 13.49,14.02
             13.49,14.02 17.14,22.00 17.14,22.00
             17.14,22.00 22.00,41.00 22.00,41.00
             28.39,34.72 28.01,19.08 32.09,14.57
             34.31,12.11 39.26,12.55 41.20,16.15
             41.20,16.15 49.00,42.00 49.00,42.00
             49.00,42.00 54.87,23.00 54.87,23.00
             54.87,23.00 58.51,14.02 58.51,14.02
             58.51,14.02 65.00,13.00 65.00,13.00
             65.00,13.00 55.72,46.00 55.72,46.00
             54.69,49.43 53.94,55.54 49.88,56.53
             45.44,57.60 43.55,54.58 42.30,50.98
             42.30,50.98 35.00,29.00 35.00,29.00
             35.00,29.00 29.14,48.00 29.14,48.00
             28.33,50.82 27.67,54.76 24.73,56.11
             18.95,58.74 16.74,53.41 15.42,49.00
             15.42,49.00 5.00,13.00 5.00,13.00 Z
           M 106.00,44.00
           C 104.84,47.02 104.00,49.30 101.66,51.67
             95.01,58.43 82.25,58.68 75.09,52.61
             70.20,48.46 68.17,42.23 68.01,36.00
             67.84,29.00 69.25,21.25 75.10,16.64
             79.44,13.22 83.73,12.89 89.00,13.01
             97.97,13.23 103.28,16.97 105.00,26.00
             105.00,26.00 96.00,27.00 96.00,27.00
             93.39,19.36 86.34,15.86 80.88,23.19
             75.88,29.89 76.74,46.90 86.00,49.76
             92.97,51.91 95.87,44.13 100.17,43.04
             102.20,42.52 104.13,43.36 106.00,44.00 Z"/>
        );
    case Config.IconSvgTaub:
      return (
        <path
          {...props}
          d="M 93.00,15.04
           C 98.18,13.70 102.22,16.73 99.81,22.00
             98.43,25.00 87.93,35.56 85.00,37.22
             79.73,40.22 75.25,35.85 77.27,31.00
             78.94,26.99 89.21,17.53 93.00,15.04 Z
           M 40.00,27.52
           C 58.12,24.65 74.12,33.06 77.86,52.00
             78.67,56.87 79.04,59.07 77.86,64.00
             75.49,75.61 69.11,79.14 66.22,86.00
             64.44,90.25 65.05,92.76 64.86,97.00
             64.58,103.16 63.00,110.47 56.98,113.43
             53.31,115.24 41.43,116.63 43.84,109.14
             44.98,105.61 49.26,105.84 51.85,104.07
             55.22,101.78 54.97,98.62 55.00,95.00
             55.03,90.39 54.83,86.41 56.61,82.00
             60.39,72.66 76.48,61.30 64.61,46.00
             63.00,43.93 61.07,42.00 59.00,40.39
             56.15,38.18 52.61,36.71 49.00,36.34
             40.15,35.43 30.97,42.10 27.53,50.00
             26.06,53.38 25.61,64.82 19.22,61.72
             14.71,59.53 16.33,52.71 17.44,49.00
             20.88,37.43 28.50,30.61 40.00,27.52 Z
           M 44.00,43.53
           C 50.51,42.52 56.27,44.20 60.00,50.04
             61.81,52.87 64.46,61.32 58.95,61.97
             54.05,62.55 54.93,52.82 47.99,51.87
             40.38,50.84 40.39,62.84 35.10,61.95
             31.55,61.35 32.07,56.47 32.80,54.00
             34.60,47.92 38.21,45.34 44.00,43.53 Z
           M 32.00,76.91
           C 36.02,76.40 39.67,78.32 38.34,83.00
             37.51,85.93 33.21,89.71 31.09,92.00
             26.71,96.71 16.38,109.22 11.00,111.45
             6.35,113.38 2.72,110.09 3.84,106.00
             4.54,103.45 8.59,99.12 10.42,97.00
             15.18,91.49 25.85,80.05 32.00,76.91 Z"
        />
      );
    case Config.IconSvgBlind:
      return (
        <path
          {...props}
          d="M 32.02,10.74
           C 36.03,9.76 40.32,9.47 43.58,12.57
             48.69,17.42 46.85,26.30 39.98,28.72
             28.75,32.69 19.58,17.60 32.02,10.74 Z
           M 19.00,51.00
           C 11.68,58.01 13.47,70.59 8.59,74.36
             5.70,76.59 2.76,74.62 2.66,70.96
             2.57,67.39 6.80,49.90 8.72,47.04
             10.43,44.51 14.54,41.25 17.00,39.26
             20.21,36.67 23.76,33.89 28.00,33.42
             29.92,33.20 32.10,33.60 34.00,33.92
             44.12,35.67 45.10,40.84 50.00,49.00
             50.00,49.00 62.51,70.00 62.51,70.00
             62.51,70.00 66.17,79.00 66.17,79.00
             66.17,79.00 76.85,98.00 76.85,98.00
             80.68,104.71 89.03,116.89 90.00,124.00
             90.00,124.00 86.61,122.41 86.61,122.41
             86.61,122.41 74.28,102.00 74.28,102.00
             74.28,102.00 63.10,83.04 63.10,83.04
             63.10,83.04 53.43,72.00 53.43,72.00
             53.43,72.00 40.00,53.00 40.00,53.00
             39.01,56.44 37.09,63.59 37.27,67.00
             37.45,70.28 39.85,75.64 41.00,79.00
             41.00,79.00 49.05,102.00 49.05,102.00
             50.28,105.36 54.50,116.00 54.45,118.98
             54.37,124.22 48.84,125.11 45.65,121.69
             43.60,119.50 38.87,106.70 37.28,103.00
             34.67,96.92 32.75,90.49 29.00,85.00
             29.00,100.92 27.18,103.45 20.41,118.00
             16.66,126.04 11.00,124.46 9.64,120.85
             8.64,118.21 10.26,114.49 11.19,112.00
             11.19,112.00 17.10,95.00 17.10,95.00
             17.10,95.00 17.10,71.00 17.10,71.00
             17.10,71.00 19.00,51.00 19.00,51.00 Z"/>
        );
    case Config.IconSvgAufzug:
      return (
        <path
          {...props}
          d="M 41.00,31.00
           C 43.44,25.85 47.90,21.92 52.00,18.02
             53.51,16.58 55.76,14.20 58.00,14.20
             60.24,14.20 62.49,16.58 64.00,18.02
             68.29,22.10 73.30,26.19 75.00,32.00
             75.00,32.00 41.00,31.00 41.00,31.00 Z
           M 38.00,15.00
           C 35.56,20.17 30.25,25.08 26.00,28.94
             24.00,30.76 21.92,32.84 19.00,31.83
             16.62,31.01 8.98,23.20 7.15,21.00
             6.05,19.67 4.15,17.46 5.17,15.60
             6.24,13.64 11.07,14.00 13.00,14.00
             19.82,13.99 31.88,13.26 38.00,15.00 Z
           M 10.11,55.02
           C 15.35,53.19 24.22,54.00 30.00,54.00
             30.00,54.00 67.00,54.00 67.00,54.00
             77.23,54.07 77.99,57.77 78.00,67.00
             78.00,67.00 78.00,99.00 78.00,99.00
             78.00,102.96 78.61,109.64 76.26,112.85
             73.68,116.39 69.88,115.99 66.00,116.00
             66.00,116.00 18.00,116.00 18.00,116.00
             13.63,115.99 9.61,116.39 7.17,111.94
             5.80,109.42 6.00,105.82 6.00,103.00
             6.00,103.00 6.00,66.00 6.00,66.00
             6.03,60.71 5.75,58.55 10.11,55.02 Z
           M 38.00,71.00
           C 38.00,71.00 15.00,71.00 15.00,71.00
             15.00,71.00 15.00,108.00 15.00,108.00
             15.00,108.00 38.00,108.00 38.00,108.00
             38.00,108.00 38.00,71.00 38.00,71.00 Z
           M 69.00,71.00
           C 69.00,71.00 46.00,71.00 46.00,71.00
             46.00,71.00 46.00,108.00 46.00,108.00
             46.00,108.00 69.00,108.00 69.00,108.00
             69.00,108.00 69.00,71.00 69.00,71.00 Z"/>
        );
    case Config.IconSvgKinderwagen:
      return (
        <path
          {...props}
            d="M 0.00,192.00
           C 0.00,192.00 0.00,178.00 0.00,178.00
             0.01,170.52 1.52,160.50 2.75,153.00
             9.93,109.20 29.09,71.77 57.59,38.00
             63.41,31.10 75.07,19.67 82.00,13.73
             90.89,6.11 97.69,0.15 110.00,0.00
             115.14,-0.06 120.02,-0.28 125.00,1.21
             142.37,6.40 150.22,23.87 159.31,38.00
             159.31,38.00 224.68,141.00 224.68,141.00
             224.68,141.00 244.34,172.00 244.34,172.00
             247.78,177.29 254.21,186.38 256.00,192.00
             256.00,192.00 0.00,192.00 0.00,192.00 Z
           M 384.00,224.00
           C 384.00,224.00 384.00,178.00 384.00,178.00
             384.00,178.00 384.00,156.00 384.00,156.00
             384.03,138.09 393.78,119.67 408.00,108.90
             424.93,96.08 436.10,96.00 456.00,96.00
             456.00,96.00 482.00,96.00 482.00,96.00
             492.32,96.00 507.35,93.26 511.35,106.00
             512.11,108.41 512.00,111.47 512.00,114.00
             512.00,114.00 512.00,144.00 512.00,144.00
             511.86,154.47 506.47,159.86 496.00,160.00
             496.00,160.00 448.00,160.00 448.00,160.00
             448.00,160.00 448.00,234.00 448.00,234.00
             447.98,244.07 445.81,255.22 443.37,265.00
             434.07,302.38 415.93,328.07 389.00,355.00
             416.38,359.23 439.57,385.98 446.11,412.00
             448.56,421.77 448.11,429.06 448.00,439.00
             447.91,446.19 446.02,453.25 443.66,460.00
             427.40,506.54 372.68,524.90 330.00,503.24
             317.59,496.95 305.86,486.06 298.87,474.00
             288.95,456.86 287.91,446.13 288.00,427.00
             288.04,418.69 289.44,415.75 291.00,408.00
             273.55,411.51 255.93,415.97 238.00,416.00
             238.00,416.00 210.00,416.00 210.00,416.00
             192.07,415.97 174.45,411.51 157.00,408.00
             158.35,414.74 159.91,418.89 160.00,426.00
             160.22,445.38 159.37,455.48 149.69,473.00
             143.21,484.72 130.89,496.59 119.00,502.74
             76.94,524.50 23.72,508.09 5.45,463.00
             -0.48,448.35 -0.17,440.26 0.00,425.00
             0.26,402.73 15.18,379.01 33.00,366.44
             40.52,361.14 49.85,356.41 59.00,355.00
             32.22,328.22 13.88,302.18 4.63,265.00
             2.19,255.22 0.02,244.07 0.00,234.00
             0.00,234.00 0.00,224.00 0.00,224.00
             0.00,224.00 384.00,224.00 384.00,224.00 Z
           M 74.00,401.30
           C 44.18,407.13 39.46,448.23 67.00,460.65
             72.44,463.10 80.13,463.57 86.00,462.70
             107.74,459.49 117.99,432.86 106.45,415.00
             98.63,402.89 87.58,399.94 74.00,401.30 Z
           M 362.00,401.30
           C 332.18,407.13 327.46,448.23 355.00,460.65
             360.44,463.10 368.13,463.57 374.00,462.70
             395.74,459.49 405.99,432.86 394.45,415.00
             386.63,402.89 375.58,399.94 362.00,401.30 Z"
          />
      );
    case Config.IconSvgRollstuhl:
      return (
        <path
          {...props}
          d="M 63.00,37.00
                C 53.41,30.82 58.31,16.26 70.00,18.23
                  78.14,19.60 81.05,29.76 75.43,35.58
                  71.95,39.19 67.48,38.49 63.00,39.00
                  73.92,45.92 59.62,56.52 55.00,62.79
                  55.00,62.79 69.00,62.79 69.00,62.79
                  72.26,62.15 76.77,61.30 79.49,63.74
                  82.20,66.17 81.26,69.83 81.08,73.00
                  81.08,73.00 79.27,96.00 79.27,96.00
                  78.80,99.15 78.60,102.90 74.89,103.92
                  65.42,106.54 68.39,91.59 68.83,87.00
                  68.83,87.00 70.00,74.00 70.00,74.00
                  70.00,74.00 61.00,74.00 61.00,74.00
                  63.86,82.75 65.60,90.02 62.07,99.00
                  60.36,103.36 57.43,110.10 52.16,104.84
                  46.57,99.25 54.28,95.95 53.61,87.00
                  52.87,77.00 42.16,67.19 32.00,68.20
                  27.61,68.63 23.82,70.99 20.00,73.00
                  20.00,73.00 13.00,65.00 13.00,65.00
                  13.00,65.00 20.00,60.99 20.00,60.99
                  20.00,60.99 28.00,57.44 28.00,57.44
                  28.00,57.44 44.00,40.00 44.00,40.00
                  41.54,39.29 37.13,37.08 35.00,37.48
                  29.89,38.44 24.37,48.61 18.23,44.97
                  17.09,44.30 16.22,43.21 15.84,41.94
                  14.48,37.33 20.05,33.32 23.00,30.72
                  25.70,28.34 29.45,24.19 33.00,23.48
                  36.45,22.79 40.11,25.22 43.00,26.85
                  48.51,29.97 57.12,36.05 63.00,37.00 Z
                M 8.00,67.00
                C 20.82,73.07 13.51,77.78 14.04,88.00
                  14.34,93.66 17.00,98.03 21.04,101.90
                  25.53,106.19 30.77,108.34 37.00,107.90
                  42.50,107.52 44.75,105.06 48.00,106.23
                  51.02,107.31 53.21,110.52 55.00,113.00
                  37.07,125.70 11.32,118.21 4.10,97.00
                  0.36,86.01 2.90,76.94 8.00,67.00 Z"
        />
      );
    case Config.IconResultSetSvgOrt:
      return (
        <path
          {...props}
          d="M 0.00,0.00
           C 0.00,0.00 77.00,0.00 77.00,0.00
             77.00,0.00 77.00,53.00 77.00,53.00
             77.00,53.00 0.00,53.00 0.00,53.00
             0.00,53.00 0.00,0.00 0.00,0.00 Z
           M 20.00,2.30
           C 9.06,4.46 1.86,13.73 4.53,25.00
             5.55,29.31 8.56,33.35 11.00,37.00
             13.12,40.16 19.45,51.60 23.83,49.83
             26.19,48.88 31.04,41.37 32.66,39.00
             35.61,34.66 39.77,29.19 40.67,24.00
             42.94,10.97 32.85,1.30 20.00,2.30 Z
           M 15.00,17.00
           C 15.65,15.12 15.93,13.82 17.42,12.31
             24.54,5.08 36.97,16.69 25.95,24.11
             24.14,25.33 23.01,25.50 21.00,26.00
             22.02,19.88 24.75,20.91 25.55,17.86
             27.22,11.48 17.16,16.67 15.00,17.00 Z
           M 60.00,41.00
           C 64.05,39.08 75.57,30.03 73.83,25.00
             72.46,21.03 63.83,13.81 60.00,12.00
             58.39,17.65 58.39,35.35 60.00,41.00 Z"
        />
      );
    case Config.IconResultSetSvgOrt_Filled:
      return (
        <path
          {...props}
          d="M 18.00,3.53
           C 28.89,1.74 39.07,7.14 39.90,19.00
             40.44,26.70 37.06,30.94 33.00,37.00
             31.13,39.79 25.16,50.53 21.28,48.83
             19.23,47.93 14.78,41.10 13.34,39.00
             6.26,28.58 -0.87,16.40 12.02,6.42
             14.22,4.71 15.45,4.35 18.00,3.53 Z
           M 26.00,19.00
           C 18.04,22.23 20.50,26.28 22.98,26.43
             24.47,26.53 26.05,25.37 27.11,24.44
             35.67,16.98 28.09,6.65 20.02,9.60
             18.54,10.14 17.45,10.97 16.42,12.15
             14.96,13.82 14.68,15.01 14.00,17.00
             21.78,18.62 22.02,11.50 26.00,19.00 Z
           M 60.00,13.00
           C 65.83,15.76 71.27,21.78 75.00,27.00
             70.37,31.63 65.98,37.17 60.00,40.00
             60.00,40.00 60.00,13.00 60.00,13.00 Z
           M 22.09,29.91
           C 17.25,32.71 21.36,36.02 23.69,34.83
             25.78,33.76 26.12,30.77 22.09,29.91 Z"
        />
      );
    case Config.IconResultSetNeu:
      return (
        <path
          {...props}
          d="M 5.02,1.02
           C 10.69,-0.89 26.21,0.00 33.00,0.00
             33.00,0.00 91.00,0.00 91.00,0.00
             91.00,0.00 285.00,0.00 285.00,0.00
             296.80,0.02 297.80,3.80 306.00,12.00
             306.00,12.00 324.00,30.00 324.00,30.00
             324.00,30.00 417.00,123.00 417.00,123.00
             417.00,123.00 488.00,194.00 488.00,194.00
             488.00,194.00 503.00,209.00 503.00,209.00
             505.67,211.67 509.93,215.50 511.26,219.00
             512.13,221.28 512.00,224.55 512.00,227.00
             512.00,227.00 512.00,499.00 512.00,499.00
             512.00,501.06 512.11,504.10 511.40,506.00
             509.16,511.96 501.45,513.34 496.00,511.26
             492.21,509.82 486.99,503.99 484.00,501.00
             484.00,501.00 408.00,425.00 408.00,425.00
             408.00,425.00 107.00,124.00 107.00,124.00
             107.00,124.00 20.00,37.00 20.00,37.00
             16.16,33.16 3.09,20.82 1.17,17.00
             -1.72,11.22 -0.12,4.72 5.02,1.02 Z
           M 250.00,141.00
           C 250.00,141.00 264.65,73.00 264.65,73.00
             264.65,73.00 268.79,54.00 268.79,54.00
             268.79,54.00 269.68,45.00 269.68,45.00
             269.68,45.00 255.00,29.06 255.00,29.06
             255.00,29.06 249.00,26.34 249.00,26.34
             249.00,26.34 242.00,32.00 242.00,32.00
             242.00,32.00 227.00,46.91 227.00,46.91
             227.00,46.91 186.00,87.00 186.00,87.00
             186.00,87.00 164.00,110.00 164.00,110.00
             164.00,110.00 176.00,121.98 176.00,121.98
             176.00,121.98 182.00,125.80 182.00,125.80
             182.00,125.80 188.00,121.98 188.00,121.98
             188.00,121.98 199.00,111.00 199.00,111.00
             199.00,111.00 243.00,68.00 243.00,68.00
             243.00,68.00 227.35,138.00 227.35,138.00
             227.35,138.00 223.00,158.00 223.00,158.00
             223.00,158.00 221.77,167.00 221.77,167.00
             221.77,167.00 227.00,174.00 227.00,174.00
             227.00,174.00 241.00,187.00 241.00,187.00
             241.00,187.00 290.00,138.01 290.00,138.01
             290.00,138.01 304.00,125.00 304.00,125.00
             304.00,125.00 320.00,109.00 320.00,109.00
             321.63,107.33 324.80,104.49 324.80,102.00
             324.80,99.76 322.42,97.51 320.98,96.00
             317.39,92.22 310.67,82.85 305.00,86.49
             305.00,86.49 289.00,102.00 289.00,102.00
             289.00,102.00 263.00,128.00 263.00,128.00
             263.00,128.00 250.00,141.00 250.00,141.00 Z
           M 347.00,122.00
           C 347.00,122.00 321.00,146.00 321.00,146.00
             321.00,146.00 284.00,183.00 284.00,183.00
             284.00,183.00 268.00,198.83 268.00,198.83
             266.12,200.53 261.96,203.59 261.34,206.01
             260.35,209.85 268.43,216.43 271.00,219.00
             271.00,219.00 300.00,248.00 300.00,248.00
             302.48,250.48 308.26,257.60 312.00,256.66
             314.65,256.00 323.94,246.67 324.55,244.00
             325.01,241.97 323.93,240.52 322.76,239.00
             322.76,239.00 304.00,220.00 304.00,220.00
             302.10,218.10 297.04,213.73 297.04,211.00
             297.04,208.07 303.85,202.15 306.00,200.00
             307.52,198.48 311.15,194.62 313.00,193.91
             316.71,192.50 320.68,197.66 323.00,200.00
             323.00,200.00 337.00,214.94 337.00,214.94
             339.00,216.76 341.08,218.84 344.00,217.83
             346.08,217.11 349.38,213.61 351.00,212.00
             353.84,209.16 355.65,207.28 358.00,204.00
             358.00,204.00 330.00,175.00 330.00,175.00
             330.00,175.00 350.00,157.00 350.00,157.00
             354.08,162.70 374.30,182.92 380.00,187.00
             380.00,187.00 395.00,173.00 395.00,173.00
             392.01,166.67 383.17,159.17 378.00,154.00
             378.00,154.00 347.00,122.00 347.00,122.00 Z
           M 480.00,258.00
           C 480.00,258.00 467.00,244.02 467.00,244.02
             465.45,242.55 463.30,240.30 461.00,240.34
             457.34,240.39 446.97,252.03 444.00,255.00
             444.00,255.00 419.00,280.00 419.00,280.00
             413.08,285.92 406.43,293.12 399.00,297.09
             381.14,306.62 366.13,286.77 367.20,276.00
             368.06,267.22 375.11,259.89 381.00,254.00
             381.00,254.00 411.00,224.00 411.00,224.00
             411.00,224.00 423.94,211.28 423.94,211.28
             425.81,209.47 427.86,207.87 426.83,204.99
             426.26,203.37 416.59,192.97 414.72,191.06
             413.14,189.47 411.46,187.72 409.00,188.34
             406.87,188.87 403.59,192.42 402.00,194.00
             402.00,194.00 388.00,208.00 388.00,208.00
             388.00,208.00 365.00,231.00 365.00,231.00
             352.85,243.15 339.00,257.21 340.09,276.00
             341.45,299.26 367.54,325.45 391.00,325.99
             417.33,326.59 436.83,300.77 454.00,284.01
             462.30,275.92 473.27,267.40 480.00,258.00 Z"
        />
      );
    default:
      return <path />;
  }
};

const IconSvg = ({
  //diese alle Variablen können wie 'props' übergeben werden,
  //dann werden die defaults überschrieben
  p_IconKey = "",
  p_Tooltip = "",
  className = "",
  style = {},
  fill = "#000",
  width = "100%",
  height = "100%",
  viewBox = ""
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(p_IconKey)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{p_Tooltip}</title>
    {getPath(p_IconKey, { fill })}
  </svg>
);
export default IconSvg;
