import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { scroller } from 'react-scroll';

import Config from '../../Config';
import ScssVariables from '../../ConfigScss.scss';
import SvgIcons from "../../common/components/SvgIcons.js";
import ResultSetComponents from '../../common/components/ResultSetComponents';
import ResultHelper from '../../common/results/ResultHelper';

import Group from '../../common/results/Group';
import MapDetail from '../../common/components/MapDetail';
import MerkmalText from '../../common/results/MerkmalText';


/**
 * This component renders the search details
 *
 * @class      SearchDetails (name)
 * @prop 		{json} dataset - api result set
 */
class SearchDetails extends Component {

	constructor(props){
		super(props);
		this.state = {
			redirectToProviderId: ''
		};

		this.ref_map = React.createRef();

		//im Konstruktor laden, da Config in der render()-Methode nicht immer definiert wird
		//und die Daten werden nur teilweise gefüllt
		this.m_configResultIds = Config.RESULT_IDS;

    this.showProviderDetails = this.showProviderDetails.bind(this);
	}

	showProviderDetails(pProviderId){
		console.log("showProviderDetails", pProviderId);
		this.setState({
			redirectToProviderId: pProviderId
		});
	}

	componentDidMount() {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });

		window.addEventListener('scroll', this.listenToScroll);
	}

	render() {
		if(this.state.redirectToProviderId){
			return <Redirect push to={"/detailorganisation/"+this.state.redirectToProviderId} />;
		}

		let offer, output;
		let dataset = this.props.dataset;
		let groups = [];
		let counter = 0;
		let shortdescription = null;
		let myeventdate = [];
		let maps_view = null;
		let accessibility_out = null;

		let elemNew = '';

		if(dataset){
			console.log(dataset);

			//get groups
			let merkmal_label;
			let merkmal_data_value;
			let ausnahmeMerkmalTypeIds = Config.DETAILVIEW_MERKMALTYPE_OFF;

			let guidShortDescription = this.m_configResultIds.short_description;

			//iterate through groups and render group
			for(let group_data of dataset.Groups)
			{
				groups.push(
					<Group group_data={group_data} key={'group-' + ++counter} p_merkmaltypeid_off={ausnahmeMerkmalTypeIds}></Group>
				);

				//try to find shortdescription
				if(!shortdescription)
				{
					let merkmal_data = ResultHelper.searchMerkmalId(group_data, guidShortDescription, true);
					if(merkmal_data)
					{
						shortdescription = (
							<div className="summary">
								<h4 className="summary-title">Kurzbeschreibung</h4>
								<MerkmalText text_data={merkmal_data}></MerkmalText>
							</div>
						);
					}
				}

				if(!myeventdate.length)
				{
					//works only, if they are all in the same group
					let n=0;
					let start_date = ResultHelper.searchMerkmalId(group_data, this.m_configResultIds.event_start_date, true);

					if(start_date)
					{
						let end_date = ResultHelper.searchMerkmalId(group_data, Config.RESULT_IDS.event_end_date, true);
						if(end_date)
							end_date = (<span> bis {ResultHelper.getFirstValue(end_date)}</span>);
						myeventdate.push(
								<div className="eventdate datevonbis" key={n++}>
									<span>{ResultHelper.getFirstValue(start_date)}</span>
									{end_date}
								</div>
							);


						let end_time = ResultHelper.searchMerkmalId(group_data, Config.RESULT_IDS.event_end_time, true);
						if(end_time)
							end_time = (<span> - {ResultHelper.getFirstValue(end_time)}</span>);
						let start_time = ResultHelper.searchMerkmalId(group_data, Config.RESULT_IDS.event_start_time, true);
						myeventdate.push(
								<div className="eventdate timevonbis" key={n++}>
									<span>{ResultHelper.getFirstValue(start_time)}</span>
									{end_time}
								</div>
							);

					}

				}
			}

			//try to get accessibility data
			let accessibility_data = ResultHelper.searchMerkmalId(dataset, Config.RESULT_IDS.accessibility);
			let accessibility_icons = [];
			let icon_map = Config.IconSvgs_Map;
			let icon_alt, icon_checknum;

			if(accessibility_data && accessibility_data.MultipleMerkmalId)
			{
				let sontstiges = '';
				//prepare icons
				for(const merkmal of accessibility_data.MultipleMerkmalId)
				{
					icon_checknum = merkmal.Values[0].FieldData;
					icon_alt = merkmal.Values[0].Value;
					if(icon_map[icon_checknum])
					{
						accessibility_icons.push(<SvgIcons p_IconKey={icon_map[icon_checknum]} p_Tooltip={icon_alt}  fill={ScssVariables.iconsSvg_color} key={icon_checknum}/>);
					}else{
						sontstiges += ' ' + icon_alt;
					}
				}

				let keineAngaben = sontstiges.length === 0 ? '' : sontstiges;
				if(accessibility_icons.length || keineAngaben.length)
				{
					//<SvgIcons className="icon_rollstuhl" p_IconKey={Config.IconSvgRollstuhl} p_Tooltip="Barrierefreiheit" fill={ScssVariables.iconsSvg_color} key='-1'/>
					accessibility_out = (
								<div className="accessibilty-icons">									
									{accessibility_icons}
									{keineAngaben}
								</div>
						);
				}
			}

			//create maps view, when lat or lng != 0
			let latitude, longitude;

			latitude = parseFloat(dataset.Latitude);
			longitude = parseFloat(dataset.Longitude);

			if(latitude || longitude)
			{
				maps_view = (
						<section className="maps mt-5 mb-5" ref={this.ref_map}>
							<MapDetail width="100%" height="400px" lat={latitude} lng={longitude}></MapDetail>
						</section>
					);
			}else{
				console.error("Koordinaten (Latitude und Longitude) sind unbekannt");
			}


			//Hole das img Element für 'neu'-Kennzeichung
			elemNew = ResultSetComponents.getElementNewDatasetIcon(dataset.DatePublished);

		}
		else{
				return <Redirect to="/404" />;
		}

		//Angebote von besonderen Organisationen mit einem Text ergänzen
		let elemOfferSpezialFlag = '';
		let providerOffersSpezialDatas = Config.SEARCHRESULTS_PROVIDERS_FOR_SPECIAL_OFFERS;
		if(providerOffersSpezialDatas){
			let providersForOffersSpezialGuids = providerOffersSpezialDatas.provider_spezial_guids.toUpperCase();

			if(providersForOffersSpezialGuids.indexOf(';'+ dataset.ParentId.toUpperCase() +';') > -1){
				elemOfferSpezialFlag =  <p className='cProviderOfferSpezial'>{providerOffersSpezialDatas.provider_spezial_text}</p>;
			}
		}

		return 	(
					<div className="search-details">
						<div className="wrapper" name="scroll_start">
							<div className="eventinfo">
								{elemNew}
								<header className="row information">
									<div className="offer-name-col">
										{myeventdate}
										<div className="offer-name">
											<h2 className="title">{dataset.LongName}</h2>
											<p className="subtitle">
												<span className="link" key={dataset.ParentId}
																					onClick={e => {this.showProviderDetails(dataset.ParentId)}}>
																					{dataset.ParentLongName}</span>
												{elemOfferSpezialFlag}
										   	<span style={{display:'none'}}>{dataset.ParentId}</span>
											</p>
										</div>
										{accessibility_out}
									</div>
									{shortdescription}
								</header>
								<article className="groups">
									{groups}
								</article>
							</div>
							{maps_view}
						</div>
					</div>
		);

	}
}

export default withRouter(SearchDetails);
