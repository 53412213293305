import React, { Component } from 'react';

import { Link } from "react-router-dom";

import Config from '../../Config';
import Helper from '../../common/tools/Helper';
import {AppContext} from '../../common/app/AppContext';

import IMGLogoStuttgart from './media/logo_stuttgart_black.svg';
import IMGLogoFrueheHilfen from './media/logo_fruehe_hilfen_footer.svg';
import IMGLogoStiftung from './media/logo_stiftung_footer.jpg';
import IMGLogoMinisterium from './media/logo_bundesministerium.svg';

class Footer extends Component {

	render() {
		let linkNutzungsbedingungen = '';
		let checkLink = null;
		try {
		 checkLink = require(`../../sites/${Config.PROJECT_FOLDER_NAME}/ViewNutzungsbedingungen.js`);
		} catch (err) {
			//wenn die Komponente nicht existiert wird eine Exception geschmiessen
		 	//return null;
		}
		if(checkLink){
			linkNutzungsbedingungen = Helper.StringToHtml('<a href="/nutzungsbedingungen/">Nutzungsbedingungen</a>&nbsp;.&nbsp;');
		}
		return(
			<footer>

				<div className="container">
					<div className="row row-1">
						<section className="col-left">
							<h3>{Config.FooterTextLinks}</h3>
							<div className="img-wrapper">
								<img className="img-fluid" src={IMGLogoStuttgart} alt="Stuttgart" />
								<img className="img-fluid img-fruehe-hilfen" src={IMGLogoFrueheHilfen} alt="Frühe Hilfen" />
								<img className="img-fluid" src={IMGLogoStiftung} alt="Bundesstiftung Frühe Hilfen" />
							</div>
						</section>

						<section className="col-right">
							<h3>{Config.FooterTextRechts}</h3>
							<div className="img-wrapper">
								<img className="img-fluid" src={IMGLogoMinisterium} alt="Bundesministerium für Familie, Senioren, Frauen und Jugend"/>
							</div>
						</section>
					</div>

					<div className="row row-2">
						<section className="gebit">
							<h3>umgesetzt durch:</h3>
							<img className="img-fluid" src={Config.ImgFooterGebit} alt="Gebit Münster"/>
							<p>{Config.FooterUmsetzungText}</p>
						</section>
					</div>
				</div>

				<div className="container-fluid">
					<div className="row">
						<section className="section-legalcopy col">
							<h3 className="title">Rechtliches und Copyrightinformationen</h3>
							<div className="desc">
								<p>Copyright GEBIT Münster | Stadt Stuttgart&nbsp;|&nbsp;{linkNutzungsbedingungen}<Link to="/impressum/">Impressum</Link>&nbsp;|&nbsp;<Link to="/datenschutz/">Datenschutzerklärung</Link></p>
							</div>
						</section>
					</div>
				</div>

			</footer>
		);
	}
}
Footer.contextType = AppContext;

export default Footer;
