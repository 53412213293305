import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Mailto from 'react-protected-mailto';
import { scroller } from 'react-scroll';

import TemplateMain from '../../basis/basis_t/TemplateMain';

/**
 * Static page: Nutzungsbedingungen
 *
 * @class      ViewNutzungsbedingungen (name)
 */
class ViewNutzungsbedingungen extends Component {
  componentDidMount() {
    scroller.scrollTo('scroll_start', {
        duration: 1000,
        smooth: true
    });
  }

  render() {
    return(
        <section className="ViewNutzungsbedingungen" name='scroll_start'>
          <div className="wrapper">
            <div className="container">
              <h1>Nutzungsbedingungen Familienwegweiser Landkreis Gifhorn</h1><br/>

              <br/>
              <h2>§ 1 Anwendungsbereich</h2>
              <p className="mb-5">
              Für die Nutzung des Portals <a href="https://familie-gifhorn.fabis.pro" target="_blank">https://familie-gifhorn.fabis.pro</a> (nachfolgend „Portal“ genannt) gelten im Verhältnis zwischen dem Nutzer und dem Anbieter der Seite die folgenden Nutzungsbedingungen.
              </p>
              <p>
                Nutzer im Sinne dieser Nutzungsvereinbarung sind die Personen und Institutionen, die nach den Regelungen dieser Nutzungsvereinbarung Daten auf das Portal einstellen können. Endnutzer sind die Bürger, die auf die veröffentlichten Daten zugreifen, ohne selbst Daten einstellen zu können. Anbieter des Portals ist der Landkreis Gifhorn. Der Anbieter ist für die Registrierung der Nutzer und die Freigabe von Inhalten der Nutzer zuständig. Die Nutzung des Portals ist nur zulässig, wenn der Nutzer diese Nutzungsbedingungen vorab akzeptiert.
              </p>


              <br/>
              <h2>§ 2 Teilnahme</h2>
              <p className="mb-5">
              1.    Der Nutzer des Portals ist mit seiner Organisation grundsätzlich im Landkreis Gifhorn ansässig. Alternativ ist die Nutzungsteilnahme möglich, wenn sich die Angebote an Familien im Landkreis Gifhorn richten und eine räumliche Nähe zum Landkreis Gifhorn gegeben ist. Den Zugang zum Portal erhält der Nutzer über den zuständigen Ansprechpartner beim Anbieter.
              </p>
              <p className="mb-5">
              2.      Der Nutzer, welcher einen Zugang zum Portal erhält, muss einem Träger/ einer Organisation angehören oder selbständiger Anbieter sein, welcher Angebote für Familien der Begriffsbestimmung des Portals erbringt.
              </p>
              <p className="mb-5">
              3.      Der Nutzer erhält personengebundene Zugangsdaten zum Portal. Der Nutzer darf seinen Zugang nicht Dritten zur Nutzung überlassen und ist verpflichtet, seine Zugangsdaten geheim zu halten und vor dem Zugriff Dritter zu schützen. Bei Verdacht des Missbrauchs durch einen Dritten haben Nutzer den Anbieter hierüber unverzüglich informieren. Sobald der Anbieter von der unberechtigten Nutzung Kenntnis erlangt, wird der Anbieter den Zugang des unberechtigten Nutzers sperren. Die Änderung des Passwortes nach der Erst-Einwahl ist erforderlich.
              </p>
              <p className="mb-5">
              4.      Die Nutzung des Portals ist für Nutzer und Endnutzer kostenfrei.
              </p>
              <p className="mb-5">
              5.      Das Erfassungsmodul des Portals ist für Nutzer zugangsdatengeschützt erreichbar unter der Adresse <a href="https://familie-gifhorn.fabis.pro" target="_blank">https://familie-gifhorn.fabis.pro</a>. Das Abfragemodul (öffentliches Portal), welches die Nutzer und deren Angebote öffentlich und zugangsdatenfrei bereitstellt, ist erreichbar unter <a href="https://familie-gifhorn.fabis.pro" target="_blank">https://familie-gifhorn.fabis.pro</a>.
              </p>
              <p className="mb-5">
              6.      Der Nutzer hat keinen Rechtsanspruch auf die Veröffentlichung seiner Daten im Portal.
              </p>
              <p className="mb-5">
              7.      Eine teilweise oder vollständige Übertragung der Rechte des Nutzers aus dieser Nutzungsvereinbarung auf Dritte ist ausgeschlossen.
              </p>


              <h2>§ 3 Haftungsausschluss</h2>
              <p className="mb-5">
              1.      Schadensersatzansprüche des Nutzers sind ausgeschlossen, soweit nachfolgend nichts anderes bestimmt ist. Der vorstehende Haftungsausschluss gilt auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen des Anbieters, sofern der Nutzer Ansprüche gegen diese geltend macht.
              </p>
              <p className="mb-5">
              2.      Von dem in Absatz 1 bestimmten Haftungsausschluss ausgenommen sind Schadensersatzansprüche aufgrund einer Verletzung des Lebens, des Körpers, der Gesundheit. Von dem Haftungsausschluss ebenfalls ausgenommen ist die Haftung für Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des Anbieters, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen.
              </p>
              <p className="mb-5">
              3.      Der Anbieter macht sich fremde Inhalte unter keinen Umständen zu eigen. Soweit über das Portal eine Möglichkeit der Weiterleitung auf Datenbanken, Websites oder Dritter, beispielsweise durch die Einstellung von Links gegeben ist, haftet der Anbieter weder für Zugänglichkeit, Bestand noch für Sicherheit dieser Drittangebote.
              </p>
              <p className="mb-5">
              4.      Der Anbieter haftet nicht für Rechtmäßigkeit, inhaltliche Richtigkeit, Vollständigkeit und Aktualität von Veröffentlichungen des Nutzers.
              </p>



              <h2>§ 4 Rechte und Pflichten des Nutzers</h2>
              <p className="mb-5">
              1.      Der Nutzer bearbeitet die Anbieter- und Angebotsdaten seiner Zuständigkeit selbst und eigenverantwortlich. Der Nutzer steht dafür ein, dass die von ihm gemachten Angaben wahr und vollständig sind. Er verpflichtet sich, alle künftigen Änderungen unverzüglich einzupflegen.
              </p>
              <p className="mb-5">
              2.      Der Nutzer verpflichtet sich gegenüber dem Anbieter, keine Angebote zu veröffentlichen, die gegen die guten Sitten oder geltendes Recht verstoßen. Der Nutzer verpflichtet sich insbesondere dazu, keine Beiträge zu veröffentlichen,
              </p>
              <p className="mb-5">
              3.      deren Veröffentlichung einen Straftatbestand erfüllt oder eine Ordnungswidrigkeit darstellt,
              <ul>
                <li>die gegen das Urheberrecht, Markenrecht oder Wettbewerbsrecht verstoßen,
                </li><li>die gegen das Rechtsdienstleistungsgesetz verstoßen,
                </li><li>die beleidigenden, rassistischen, diskriminierenden oder pornographischen Inhalt haben,
                </li><li>die Werbung enthalten, welche über die Bewerbung - eines Angebots für Familien - hinausgeht.
                </li>
              </ul>
              </p>
              <p className="mb-5">
              5.      Der Nutzer ist für die erforderlichen Datensicherungsvorkehrungen verantwortlich.
              </p>
              <p className="mb-5">
              6.      Der Nutzer verpflichtet sich, alle Maßnahmen zu unterlassen, welche die Funktionsweise des Portals gefährden oder stören, sowie nicht auf Daten zu zugreifen, zu deren Zugang er nicht berechtigt ist.
              </p>




              <h2>§ 5 Rechte und Pflichten des Anbieters</h2>
              <p className="mb-5">
              1.      Der Anbieter gestattet dem Nutzer, im Rahmen dieser Nutzungsbedingungen die Darstellung seiner Organisation sowie der erbrachten Angebote im Kontext Angebote für Familien im Landkreis Gifhorn mit dem Ziel der Veröffentlichung unter <a href="https://www.familienwegweiser-gifhorn.de" target="_blank">www.familienwegweiser-gifhorn.de</a>. Der Anbieter ist bemüht, seinen Dienst verfügbar zu halten. Der Anbieter übernimmt keine darüber hinausgehenden Leistungspflichten. Insbesondere besteht kein Anspruch des Nutzers auf eine ständige Verfügbarkeit des Portals.
              </p>
              <p className="mb-5">
              2.      Der Anbieter überprüft die Angebotsdaten und schaltet diese frei. Überprüft wird, ob sich das Angebot im Rahmen der Begriffsbestimmung des Portals bewegt und ob Verstöße gemäß Nr. 5 dieser Nutzungsvereinbarung vorliegen.
              </p>
              <p className="mb-5">
              3.      Bei einem Verstoß gegen die Verpflichtung aus dieser Nutzungsvereinbarung ist der Anbieter berechtigt, die entsprechenden Beiträge im Portal zu löschen und den Zugang des Nutzers zu sperren. Der Nutzer ist verpflichtet, dem Anbieter den durch die Pflichtverletzung entstandenen Schaden zu ersetzen.
              </p>


              <h2>§ 6 Übertragung von Nutzungsrechten</h2>
              <p className="mb-5">
              1.      Der Nutzer erklärt sich einverstanden, dass die eingegebenen und zur Veröffentlichung freigegebenen Daten über das Portal öffentlich verfügbar sind.
              </p>
              <p className="mb-5">
              2.      Der Nutzer erklärt sich einverstanden, dass die Daten in aggregierter Form (im Rahmen der Landesjugendhilfeplanung und kommunaler Jugendhilfeplanung) nutzbar gemacht werden.
              </p>


              <h2>§ 7 Vertragsdauer, Beendigung der Nutzung</h2>
              <p className="mb-5">
              1.      Diese Nutzungsvereinbarung wird auf unbestimmte Zeit abgeschlossen. Die Nutzungsvereinbarung kann von beiden Parteien mit einer Frist von einem Tag gekündigt werden. Mit Ablauf der Kündigungsfrist wird der Zugang des Nutzers zum Portal deaktiviert.
              </p>
              <p className="mb-5">
              2.      Der Nutzer kann seinen Zugang durch eine entsprechende Erklärung gegenüber dem Anbieter ohne Einhaltung einer Frist beenden. Auf Verlangen wird der Anbieter daraufhin den Zugang des Nutzers sperren.
              </p>
              <p className="mb-5">
              3.      Der Anbieter ist berechtigt, den Zugang eines Nutzers zu deaktivieren, wenn ein hinreichender Verdacht besteht, dass der Nutzer gegen die Nutzungsvereinbarung verstoßen hat. Der Anbieter ist in diesem Falle auch berechtigt, die vom Nutzer im Portal eingestellten Inhalte zu löschen, soweit dies nicht bereits von seitens des Nutzers erfolgt ist
              </p>
              <p className="mb-5">
              4.      Das Recht zur außerordentlichen Kündigung dieser Nutzungsvereinbarung bleibt unberührt.
              </p>



              <h2>§ 8 Änderung oder Einstellung des Dienstes</h2>
              <p className="mb-5">
                1.      Der Anbieter ist berechtigt, technische und inhaltliche Änderungen am Portal vorzunehmen. Diese werden in der aktuellen Anleitung zum Programm hinterlegt.
              </p>
              <p className="mb-5">
                2.      Der Anbieter ist berechtigt, seinen Dienst unter Einhaltung einer Ankündigungsfrist von 2 Wochen einzustellen. Im Falle der Beendigung seines Dienstes ist der Anbieter berechtigt, die von den Nutzern im Portal eingestellten Inhalte zu löschen.
              </p>




              <h2>§ 9 Datenschutz</h2>
              <p className="mb-5">
                1.      Die Daten im Portal werden ausschließlich zum Zweck der Information für Bürger und Fachkräfte über die Verfügbarkeit von Leistungen für Familien mit Kindern und Jugendlichen im Landkreis Gifhorn erhoben, verarbeitet, genutzt. Personenbezogene Daten werden nur zum Zwecke der Anlage der Benutzeraccounts oder zur Bekanntgabe eines Ansprechpartners für einen Nutzer oder ein Angebot erhoben. Letzteres geschieht nur, wenn diese Daten a) bereits öffentlich sind oder b) die Person der Erfassung zugestimmt hat. Dieser Erhebung, Verarbeitung und Nutzung kann durch den Nutzer des Portals jederzeit widersprochen werden.
              </p>
              <p className="mb-5">
                1.      Die Daten im Portal werden ausschließlich zum Zweck der Information für Bürger und Fachkräfte über die Verfügbarkeit von Leistungen für Familien mit Kindern und Jugendlichen im Landkreis Gifhorn erhoben, verarbeitet, genutzt. Personenbezogene Daten werden nur zum Zwecke der Anlage der Benutzeraccounts oder zur Bekanntgabe eines Ansprechpartners für einen Nutzer oder ein Angebot erhoben. Letzteres geschieht nur, wenn diese Daten a) bereits öffentlich sind oder b) die Person der Erfassung zugestimmt hat. Dieser Erhebung, Verarbeitung und Nutzung kann durch den Nutzer des Portals jederzeit widersprochen werden.
              </p>
              <p className="mb-5">
                2.      Veröffentlichte Daten im Detail
              </p>
              <div style={{paddingLeft:'20px'}}>
              <p className="mb-5">
              a.      Es werden Nutzerdaten zur Erstellung eines Userzugangs im nichtöffentlichen Erfassungsmodul erhoben. Dies betrifft Nutzer, welche das Portal zur Erfassung und Veröffentlichung eigener Angebote nutzen. Hierbei handelt es sich um Name, Vorname sowie Emailadresse.
              <br/>
              b.      Es werden Personendaten erhoben, um einen Ansprechpartner eines Angebotes oder Nutzers im Portal auszuweisen. Ziel ist die Ermöglichung der Kontaktaufnahme durch einen Bürger/eine Fachkraft. Hierbei handelt es sich um den Namen und Kontaktdaten (Telefon/Fax/Email).
              </p></div>



              <h2>§ 10 Schlussbestimmungen</h2>
              <p className="mb-5">
                1.      Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrecht. Ausschließlicher Gerichtsstand für alle Rechtsstreitigkeiten ist der Geschäftssitz des Anbieters, soweit die Vertragsparteien Kaufleute, juristische Personen des öffentlichen Rechts oder öffentlich-rechtliche Sondervermögen sind.
              </p>
              <p>
                2.      Sollten einzelne Bestimmungen dieser Nutzungsvereinbarung unwirksam sein oder werden und/oder den gesetzlichen Regelungen widersprechen, so wird hierdurch die Wirksamkeit der Nutzungsvereinbarung im Übrigen nicht berührt. Die unwirksame Bestimmung wird von den Vertragsparteien einvernehmlich durch eine solche Bestimmung ersetzt, welche dem wirtschaftlichen Zweck der unwirksamen Bestimmung in rechtswirksamer Weise am nächsten kommt. Die vorstehende Regelung gilt entsprechend bei Regelungslücken.
              </p>


              <br/><br/>
              <h4>Münster, November 2020</h4>
            </div>
          </div>
        </section>
	);
  }
}

export default ViewNutzungsbedingungen;
